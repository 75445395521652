// import CRMLayout from 'layouts/CRMLayout';
import React, {lazy, Suspense} from 'react';
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
    useLocation,
    useRoutes,
} from 'react-router-dom';
import {
    paths,
    PATH_AFTER_LOGIN,
    PATH_TO_REDIRECTOR,
    PATH_AFTER_ADMIN_LOGIN,
    adminPaths,
    authPaths,
    devPaths,
} from './path';
import GuestLayout from 'layouts/GuestLayout';
import GuestGuard from 'layouts/guard/GuestGuard';
import MainLayout from 'layouts/MainLayout';
import AuthGuard from 'layouts/guard/AuthGuard';
import LoadingScreen from 'page/LoadingScreen';
import AppLayout from 'layouts/AppLayout';
import HelpCenterLayout from 'layouts/HelpCenterLayout';
import {useAuth} from 'hooks/useAuth';
import AdminGuard from 'layouts/guard/AdminGuard';
import ProfileLayout from 'layouts/ProfileLayout';
import {get} from 'lodash';
import MetadataLayout from 'layouts/MetadataLayout';
import PaymentGatewayLayout from 'layouts/PaymentGatewayLayout';
import GeneralSettingLayout from 'layouts/GeneralSettingLayout';
import IntergrationLayout from 'layouts/IntergrationLayout';
import AccessControlLayout from 'layouts/AccessControlLayout';
import ApiManagementLayout from 'layouts/ApiManagementLayout';
import SystemMonitorLayout from 'layouts/SystemMonitorLayout';
import SecurityManagementLayout from 'layouts/SecurityManagementLayout';
import BulkDataLayout from 'layouts/BulkDataLayout';
import FinanceLayout from 'layouts/FinanceLayout';
import TradesLayout from 'layouts/TradesLayout';
import TaskboardLayout from 'layouts/TaskboardLayout';
import ApprovalsLayout from 'layouts/ApprovalsLayout';
import CurrenciesLayout from 'layouts/CurrenciesLayout';
import AdminPermissionGuard from 'layouts/guard/AdminPermissionGuard';
import NewsLayout from '../layouts/NewsLayout';
import FundingLayout from 'layouts/FundingLayout';
import IBLayout from 'layouts/IBLayout';
import RiskReportsLayout from 'layouts/RiskReportsLayout';
import MtMonitorLayout from 'layouts/MtMonitorLayout';
import RequestLayout from 'layouts/RequestLayout';
import RegulationRegionLayout from 'layouts/RegulationRegionLayout';
import Sublevels from '../page/app/IB/Sublevels';
import Mt45SettingsLayout from 'layouts/Mt45SettingsLayout';
import KYCRequireGuard from 'layouts/guard/KYCRequireGuard';
import InformationEmailLayout from 'layouts/InformationEmailLayout';
import {useUIStore} from 'stores/uiStore';
import {SWRConfig} from 'swr';
import {RNConfig} from '../swr/config';
import client, {adminClient} from 'helper/httpClient';
import { useAuthStore } from 'stores/authStore';
import StackLayout from '../layouts/StackLayout';
import StockRequestLayout from '../layouts/StockRequestLayout';
import StockTaskboardLayout from '../layouts/StockTaskboardLayout';
import SafeLoadingComponent, {
    ComponentError,
} from 'components/SafeLoadingComponent';
import MaintenanceGuard from 'layouts/guard/MaintenanceGuard';
import PassOtpResetRequiredGuard from 'layouts/guard/PassOtpResetRequiredGuard';
import ConfidencialLayout from 'layouts/ConfidencialLayout'
import OXGuestLayout from 'layouts/OXGuestLayout/OXGuestLayout';
import CampaignsLayout from 'layouts/CampaignsLayout';
import UnMaintenanceGuard from 'layouts/guard/UnMaintenanceGuard';
import ManagementLayout from 'layouts/ManagementLayout'
import ClosedPnlLayout from 'layouts/ClosedPnlLayout'

const getDevRoute = () => {
    return [
        {
            path: 'dev',
            children: [
                {
                    element: <Navigate to={devPaths.apis} replace />,
                    index: true,
                },
                {
                    path: 'api-explorer',
                    element: <API />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('explorer'),
                            path: devPaths.apis,
                        }),
                    },
                },
            ],
        },
    ];
};

const getAuthRoute = () => {
    return [
        {
            path: 'auth',
            element: (
                <MaintenanceGuard>
                    <OXGuestLayout />
                </MaintenanceGuard>
            ),
            children: [
                {
                    element: <Navigate to={authPaths.login} replace />,
                    index: true,
                },
                {
                    path: 'login',
                    element: <Login />,
                },
                {
                    path: 'sign-up',
                    element: (
                        <GuestGuard>
                            <Register />
                        </GuestGuard>
                    ),
                },
                {
                    path: 'required-update-pass-otp',
                    element: (
                        <PassOtpResetComposePage />
                    ),
                },
                {path: 'logout', element: <Logout />},
                {path: 'sign-up-verify', element: <SignUpVerify />},
                {path: 'sign-up-link-verify', element: <SignUpLinkVerify />},
                {path: 'forgot-password', element: <ForgotPassword />},
                {path: 'reset-password', element: <ResetPassword />},
            ],
        }
    ];
};

export const getAppRoute = () => {
    return [
        {
            path: 'app',
            element: (
                <MaintenanceGuard>
                    <AuthGuard>
                        <AppLayout />
                    </AuthGuard>
                </MaintenanceGuard>
            ),
            children: [
                {
                    element: <Navigate to={PATH_AFTER_LOGIN} replace />,
                    index: true,
                },
                {
                    path: 'dashboard',
                    element: <Dashboard />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('dashboard'),
                            path: paths.dashboard,
                        }),
                    },
                },
                {
                    path: 'kyc',
                    element: <KYC />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('kyc'),
                            path: paths.kyc,
                        }),
                    },
                },
                {
                    path: 'kyb',
                    element: <KYB />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('kyb'),
                            path: paths.kyb,
                        }),
                    },
                },
                {path: 'logout', element: <Logout />},
                {
                    path: 'profile',
                    element: <ProfileLayout />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('profile'),
                            path: paths.profile,
                        }),
                    },
                    children: [
                        {
                            element: <Profile />,
                            index: true,
                        },
                        {
                            path: 'kyc',
                            element: <ProfileKYCReview />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('kyc'),
                                    path: paths.profileKycReview,
                                }),
                            },
                        },
                        {
                            path: 'preference',
                            element: <Preference />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('preference'),
                                    path: paths.preference,
                                }),
                            },
                        },
                        {
                            path: 'security',
                            element: <Security />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('security'),
                                    path: paths.security,
                                }),
                            },
                        },
                        {
                            path: 'notification',
                            element: <NotificationSetting />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('notification'),
                                    path: paths.notificationSetting,
                                }),
                            },
                        },
                        {
                            path: 'crypto-wallet',
                            element: <ProfileCryptoWalletSetting />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('cryptoWallet'),
                                    path: paths.profileCryptoWallet,
                                }),
                            },
                        },
                    ],
                },
                {
                    path: 'referral',
                    children: [
                        {
                            element: <Navigate to={paths.referees} replace />,
                            index: true,
                        },
                        {
                            path: 'referees',
                            element: <Referral />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('referees'),
                                    path: paths.referees,
                                }),
                            },
                        },
                        {
                            path: 'commission',
                            element: <Commission />,
                            handle: {
                                crumb: ({params, i18n}) => ({
                                    label: i18n('commission'),
                                    path: paths.referralCommission,
                                }),
                            },
                        },
                    ],
                },
                {
                    path: 'apis',
                    children: [
                        {
                            element: <Navigate to={devPaths.apis} replace />,
                            index: true,
                        },
                        {
                            path: 'api-log',
                            element: <APILogs />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('apiLog'),
                                    path: paths.apiLog,
                                }),
                            },
                        },
                        {
                            path: 'api-key',
                            element: <APIKey />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('apiKey'),
                                    path: paths.apiKey,
                                }),
                            },
                        },
                    ],
                },
                {
                    path: 'help-center',
                    element: <HelpCenterLayout />,
                    children: [
                        {
                            element: <Navigate to={paths.support} replace />,
                            index: true,
                        },
                        {
                            path: 'support',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('support'),
                                    path: paths.support,
                                }),
                            },
                            children: [
                                {
                                    element: <Support />,
                                    index: true,
                                },
                                {
                                    path: ':id',
                                    element: <SupportDetail />,
                                    handle: {
                                        crumb: ({data, i18n}) => ({
                                            label: i18n('supportDetail'),
                                            path: paths.supportDetail(
                                                get(data, 'id'),
                                            ),
                                        }),
                                    },
                                },
                                {
                                    path: 'newSupport',
                                    element: <SupportDetail />,
                                    handle: {
                                        crumb: ({data, i18n}) => ({
                                            label: i18n('newSupport'),
                                            path: paths.newSupport,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'q-and-a',
                            element: <QAndA />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('QAndA'),
                                    path: paths.QAndA,
                                }),
                            },
                        },
                    ],
                    // handle: {
                    //     crumb: ({data, i18n}) => ({
                    //         label: i18n('helpCenter'),
                    //         path: paths.helpCenter,
                    //     }),
                    // },
                },
                {
                    path: 'components',
                    element: <Components />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('components'),
                            path: paths.components,
                        }),
                    },
                },
                {path: 'settings', element: <Settings />},
                {path: '404', element: <Page404 />},
                {path: '*', element: <Navigate to="/crm/404" replace />},
                {
                    path: 'mtAccounts',
                    element: (
                        <KYCRequireGuard>
                            <Accounts />
                        </KYCRequireGuard>
                    ),
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('mtAccounts'),
                            path: paths.accounts,
                        }),
                    },
                },
                {
                    path: 'stock',
                    element: (
                        <KYCRequireGuard>
                            <StackLayout />
                        </KYCRequireGuard>
                    ),
                    children: [
                        {
                            element: (
                                <Navigate to={paths.shareAccount} replace/>
                            ),
                            index: true,
                        },
                        {
                            path: 'shareAccount',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('shareAccount'),
                                    path: paths.shareAccount,
                                }),
                            },
                            element: <StockSummary />,
                        },
                        {
                            path: 'account',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('account'),
                                    path: paths.stockAccount,
                                }),
                            },
                            element: <StockAccount />,
                        },
                        {
                            path: 'positions',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('positions'),
                                    path: paths.stockPosition,
                                }),
                            },
                            element: <StockPosition />,
                        },
                        {
                            path: 'transactions',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('transactions'),
                                    path: paths.stockTransaction,
                                }),
                            },
                            element: <StockTransaction />,
                        },
                        {
                            path: 'live-data-subscriptions',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('liveDataSubscriptions'),
                                    path: paths.stockSubscriptions,
                                }),
                            },
                            element: <StockLiveSubscriptions />,
                        },
                        {
                            path: 'stock-products',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('stockCFDProducts'),
                                    path: paths.stockProducts,
                                }),
                            },
                            element: <StockProducts />,
                        },
                        {
                            path: 'history',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('history'),
                                    path: paths.stockHistory,
                                }),
                            },
                            element: <StockHistory />,
                        },
                        {
                            path: 'statements',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('statements'),
                                    path: paths.stockStatement,
                                }),
                            },
                            element: <StockStatement />,
                        },
                    ],
                },
                {
                    path: 'funding',
                    element: (
                        <KYCRequireGuard>
                            <FundingLayout />
                        </KYCRequireGuard>
                    ),
                    children: [
                        {
                            element: (
                                <Navigate to={paths.newDeposit} replace />
                            ),
                            index: true,
                        },
                        {
                            path: 'funding-summary',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('fundingSummary'),
                                    path: paths.fundingSummary,
                                }),
                            },
                            element: <FundingSummary />,
                        },
                        {
                            path: 'deposits',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('deposits'),
                                    path: paths.deposits,
                                }),
                            },
                            children: [
                                {
                                    element: <Deposit />,
                                    index: true,
                                },
                                {
                                    path: 'channel-deposit-complete',
                                    element: <ChannelDepositCompletePage />,
                                    handle: {
                                        crumb: ({data, i18n}) => ({
                                            label: i18n(
                                                'channelDepositComplete',
                                            ),
                                            path: paths.channelDepositComplete,
                                        }),
                                    },
                                },
                                {
                                    path: 'channel-deposit-cancel',
                                    element: <ChannelDepositCancelPage />,
                                    handle: {
                                        crumb: ({data, i18n}) => ({
                                            label: i18n('channelDepositCancel'),
                                            path: paths.channelDepositCancel,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'newDeposit',
                            element: <NewDeposit />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('deposits'),
                                    path: paths.newDeposit,
                                }),
                            },
                        },
                        {
                            path: 'withdrawals',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('Withdrawals'),
                                    path: paths.withdrawals,
                                }),
                            },
                            children: [
                                {
                                    element: <Withdrawal />,
                                    index: true,
                                },
                                {
                                    path: 'creatWithdrawal',
                                    element: <CreatWithdrawal />,
                                    handle: {
                                        crumb: ({data, i18n}) => ({
                                            label: i18n('withdrawals'),
                                            path: paths.creatWithdrawal,
                                        }),
                                    },
                                },
                                {
                                    path: 'withdrawalSuccess',
                                    element: <WithdrawalSuccess />,
                                    handle: {
                                        crumb: ({data, i18n}) => ({
                                            label: i18n('withdrawals'),
                                            path: paths.withdrawalSuccess,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'transfers',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('transfers'),
                                    path: paths.transfers,
                                }),
                            },
                            children: [
                                {
                                    element: <Transfer />,
                                    index: true,
                                },
                                {
                                    path: 'creatTransfer',
                                    element: <CreatTransfer />,
                                    handle: {
                                        crumb: ({data, i18n}) => ({
                                            label: i18n('transfers'),
                                            path: paths.creatTransfer,
                                        }),
                                    },
                                },
                                {
                                    path: 'transferSuccess',
                                    element: <TransferSuccess />,
                                    handle: {
                                        crumb: ({data, i18n}) => ({
                                            label: i18n('transfers'),
                                            path: paths.transferSuccess,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'commissions',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('commissions'),
                                    path: paths.commissions,
                                }),
                            },
                            element: <FundingCommissions />,
                        },
                        {
                            path: 'moneyMove',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('moneyMove'),
                                    path: paths.moneyMove,
                                }),
                            },
                            element: <FundingMoneyMove />,
                        },
                        {
                            path: 'funding-withdrawal-accounts',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('withdrawalAccounts'),
                                    path: paths.withdrawalAccounts,
                                }),
                            },
                            element: <FundingWithdrawalAccounts />,
                        },
                        {
                            path: 'statements',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('statements'),
                                    path: paths.statements,
                                }),
                            },
                            element: <FundingStatements />,
                        },
                    ],
                },
                {
                    path: 'ib-agent',
                    element: <IBLayout />,
                    children: [
                        {
                            element: <Navigate to={paths.ibOverview} replace />,
                            index: true,
                        },
                        {
                            path: 'ib-overview',
                            element: <IBOverview />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('overview'),
                                    path: paths.ibOverview,
                                }),
                            },
                        },
                        {
                            path: 'wired-accounts',
                            element: <ClientWiredAccount />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('sublevels'),
                                    path: paths.ibSublevels,
                                }),
                            },
                        },
                        {
                            path: 'ib-sublevels',
                            element: <IBSublevels />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('sublevels'),
                                    path: paths.ibSublevels,
                                }),
                            },
                        },
                        {
                            path: 'ib-reports',
                            element: <IBReports />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('sublevels'),
                                    path: paths.ibSublevels,
                                }),
                            },
                        },
                        {
                            path: 'pending-invitations',
                            element: <PendingInvitations />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('pendingInvitations'),
                                    path: paths.pendingInvitations,
                                }),
                            },
                        },
                    ],
                },
                {
                    path: 'newsPage',
                    element: <NewsLayout />,
                    children: [
                        {
                            element: <Navigate to={paths.news} replace />,
                            index: true,
                        },
                        {
                            path: 'news',
                            element: <News />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('news'),
                                    path: paths.news,
                                }),
                            },
                        },
                        {
                            path: 'announcements',
                            element: <Announcements />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('announcements'),
                                    path: paths.announcements,
                                }),
                            },
                        },
                    ],
                },
                {
                    path: 'support',
                    element: <Support />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('support'),
                            path: paths.support,
                        }),
                    },
                },
                {
                    path: 'downloads',
                    element: <Tools />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('downloads'),
                            path: paths.downloads,
                        }),
                    },
                },
                {
                    path: 'help',
                    element: <Help />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('help'),
                            path: paths.help,
                        }),
                    },
                },
                {
                    path: 'aboutUs',
                    element: <AboutUs />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('aboutUs'),
                            path: paths.aboutUs,
                        }),
                    },
                },

                {
                    path: 'notification',
                    element: <Notification />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('notification'),
                            path: paths.notification,
                        }),
                    },
                },
            ],
        },
    ];
};

export const getEdgeCaseRoute = () => {
    return [
        {
            path: '/',
            ErrorBoundary: ComponentError,
            element: <Navigate to={'/redirector'} replace />,
        },
        {
            path: '/redirector',
            element: <Redirector replace />,
        },
        {
            path: '*',
            element: <OXGuestLayout />,
            children: [
                {path: 'maintenance', element: <UnMaintenanceGuard>
                    <Maintenance />
                </UnMaintenanceGuard>
                },
                {path: '404', element: <Page404 />},
                {path: '403', element: <Page403 />},
                {path: '*', element: <Navigate to="/404" replace />},
            ],
        },
    ];
};

export const getPublicRoute = () => {
    return [
        {
            path: 'public',
            element: (
                <MaintenanceGuard>
                    <GuestLayout center={false} />
                </MaintenanceGuard>
            ),
            children: [
                {
                    path: 'help',
                    element: <GuestHelp />,
                },
                {path: '*', element: <Navigate to="/404" replace />},
            ],
        },
    ];
};

export const getAdminRoute = () => {
    return [
        {
            path: 'admin',
            element: (
                <MaintenanceGuard>
                    <AdminGuard>
                        <PassOtpResetRequiredGuard>
                            <AppLayout />
                        </PassOtpResetRequiredGuard>
                    </AdminGuard>
                </MaintenanceGuard>
            ),
            children: [
                {
                    element: <Navigate to={PATH_AFTER_ADMIN_LOGIN} replace />,
                    index: true,
                },
                {
                    path: 'confidencial',
                    element: <ConfidencialLayout />,
                    children: [
                        {
                            element: (
                                <Navigate
                                    to={adminPaths.pammAccountSummary}
                                    replace
                                />
                            ),
                            index: true,
                        },
                        {
                            path: 'dashboard',
                            element: <ConfidencialDashboard />,
                        },
                        {
                            path: 'pamm',
                            children: [
                                {
                                    element: <ConfidencialAdmin />,
                                    index: true,
                                },
                                // {
                                //     path: ':id',
                                //     element: <ConfidencialAdminDetail /> ,
                                // }
                            ],
                        },
                    ],
                },
                {
                    path: 'confidencial/pamm-detail/:id',
                    element: <ConfidencialAdminDetail />,
                },
                {
                    path: 'dashboard',
                    element: <AdminDashboard />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('dashboard'),
                            path: adminPaths.dashboard,
                        }),
                    },
                },
                {
                    path: 'opensearch-dashboard',
                    element: <AdminOpensearchDashboard />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('dashboard'),
                            path: adminPaths.dashboard,
                        }),
                    },
                },
                {
                    path: 'analytics',
                    children: [
                        {
                            path: 'closedtrades',
                            element: <AdminPermissionGuard permissionKey={'tasks'}>
                                <ManagementLayout />
                            </AdminPermissionGuard>,
                            children: [
                                {
                                    path: 'lots',
                                    element: <VolumeAnalysis />,
                                    handle: {
                                        crumb: ({data, i18n}) => ({
                                            label: i18n('volumeAnalysis'),
                                            path: adminPaths.dashboard,
                                        }),
                                    },
                                },
                                {
                                    path: 'trades',
                                    element: <TradeAccountAnalysis />,
                                    handle: {
                                        crumb: ({data, i18n}) => ({
                                            label: i18n('tradeAccountAnalysis'),
                                            path: adminPaths.dashboard,
                                        }),
                                    },
                                },
                                {
                                    path: 'closed-pnl',
                                    element: <ClosedPnlAnalysis />,
                                    handle: {
                                        crumb: ({data, i18n}) => ({
                                            label: i18n('closedPnlAnalysis'),
                                            path: adminPaths.dashboard,
                                        }),
                                    },
                                },
                                {
                                    path: 'pnl',
                                    element: <ClosedPnlLayout />,
                                    children: [
                                        {
                                            element: (
                                                <Navigate
                                                    to={adminPaths.managementReport.bySymbol}
                                                    replace
                                                />
                                            ),
                                            index: true,
                                        },
                                        {
                                            path: 'symbol',
                                            element: <ClosedPnlBySymbol />
                                        },
                                        {
                                            path: 'currencies',
                                            element: <ClosedByCurrencies />
                                        },
                                        {
                                            path: 'groups',
                                            element: <ClosedByGroups />
                                        },
                                        {
                                            path: 'books',
                                            element: <ClosedByBooks />
                                        },
                                        {
                                            path: 'top',
                                            element: <ClosedByGainerOrLosers />
                                        },
                                        {
                                            path: 'trades',
                                            element: <ClosedByTrades />
                                        },
                                        {
                                            path: 'accounts',
                                            element: <ClosedByAccounts />
                                        },
                                        {
                                            path: 'servers',
                                            element: <ClosedByServers />
                                        },
                                    ]
                                },
                                {
                                    path: 'commission',
                                    element: <ClosedCommissionAnalysis />,
                                    handle: {
                                        crumb: ({data, i18n}) => ({
                                            label: i18n('commissionAnalysis'),
                                            path: adminPaths.dashboard,
                                        }),
                                    },
                                },
                                {
                                    path: 'holding',
                                    element: <HoldingPositionsAnalysis />,
                                    handle: {
                                        crumb: ({data, i18n}) => ({
                                            label: i18n('holdingPositionsAnalysis'),
                                            path: adminPaths.dashboard,
                                        }),
                                    },
                                }
                            ]
                        },
                    ]
                },
                {
                    path: 'tasks',
                    element: <AdminPermissionGuard permissionKey={'tasks'} />,
                    children: [
                        {
                            path: 'requests',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('requests'),
                                    path: adminPaths.requests,
                                }),
                            },
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'tasks.requests'}
                                >
                                    <RequestLayout />
                                    {/* <AdminRequests /> */}
                                </AdminPermissionGuard>
                            ),
                            children: [
                                {
                                    element: (
                                        <Navigate
                                            to={adminPaths.requestSummary}
                                            replace
                                        />
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'summary',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'tasks.requests.requestSummary'
                                            }
                                        >
                                            <AdminRequests />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('requestSummary'),
                                            path: adminPaths.requestSummary,
                                        }),
                                    },
                                },
                                {
                                    path: 'deposits',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'tasks.requests.requestDeposits'
                                            }
                                        >
                                            <AdminRequests />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('requestDeposits'),
                                            path: adminPaths.requestDeposits,
                                        }),
                                    },
                                },
                                {
                                    path: 'accounts',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'tasks.requests.requestAccount'
                                            }
                                        >
                                            <AdminRequests />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('account_applications'),
                                            path: adminPaths.requestAccount,
                                        }),
                                    },
                                },
                                {
                                    path: 'withdrawals',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'tasks.requests.requestWithdrawals'
                                            }
                                        >
                                            <AdminRequests />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('requestWithdrawals'),
                                            path: adminPaths.requestWithdrawals,
                                        }),
                                    },
                                },
                                {
                                    path: 'transfers',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'tasks.requests.requestTransfer'
                                            }
                                        >
                                            <AdminRequests />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('requestTransfer'),
                                            path: adminPaths.requestTransfer,
                                        }),
                                    },
                                },
                                {
                                    path: 'leverages',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'tasks.requests.requestLeverage'
                                            }
                                        >
                                            <AdminRequests />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('requestLeverage'),
                                            path: adminPaths.requestLeverage,
                                        }),
                                    },
                                },
                                {
                                    path: 'passwords',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'tasks.requests.requestPassword'
                                            }
                                        >
                                            <AdminRequests />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('requestPassword'),
                                            path: adminPaths.requestPassword,
                                        }),
                                    },
                                },
                                {
                                    path: 'kyc',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'tasks.requests.requestKyc'
                                            }
                                        >
                                            <AdminRequests />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('requestKyc'),
                                            path: adminPaths.requestKyc,
                                        }),
                                    },
                                },
                                {
                                    path: 'IB',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'tasks.requests.requestIB'
                                            }
                                        >
                                            <AdminRequests />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('requestIB'),
                                            path: adminPaths.requestIB,
                                        }),
                                    },
                                },
                                {
                                    path: 'bankAccounts',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'tasks.requests.requestWithdrawalAccount'
                                            }
                                        >
                                            <AdminRequests />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n(
                                                'requestWithdrawalAccount',
                                            ),
                                            path: adminPaths.requestWithdrawalAccount,
                                        }),
                                    },
                                },
                                {
                                    path: 'wallets',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'tasks.requests.requestWithdrawalWallet'
                                            }
                                        >
                                            <AdminRequests />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n(
                                                'requestWithdrawalWallet',
                                            ),
                                            path: adminPaths.requestWithdrawalWallet,
                                        }),
                                    },
                                },
                                {
                                    path: 'exchangeWithdrawal',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'tasks.requests.requestWithdrawalWallet'
                                            }
                                        >
                                            <AdminRequests />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n(
                                                'requestWithdrawalWallet',
                                            ),
                                            path: adminPaths.requestExchangeWithdrawal,
                                        }),
                                    },
                                },
                                {
                                    path: 'generals',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'tasks.requests.requestGeneral'
                                            }
                                        >
                                            <AdminRequests />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('requestGeneral'),
                                            path: adminPaths.requestGeneral,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'my-tasks',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('myTasks'),
                                    path: adminPaths.myTasks,
                                }),
                            },
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'tasks.myTasks'}
                                >
                                    <AdminMyTasks />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'taskboard',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('taskboard'),
                                    path: adminPaths.taskboard,
                                }),
                            },
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'tasks.taskboard'}
                                >
                                    <TaskboardLayout />
                                </AdminPermissionGuard>
                            ),
                            children: [
                                {
                                    element: (
                                        <Navigate
                                            to={adminPaths.taskboardDeposits}
                                            replace
                                        />
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'taskboard-kyc',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'tasks.taskboard.taskboardKyc'
                                            }
                                        >
                                            <AdminTaskboardKYC />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('taskboardKyc'),
                                            path: adminPaths.taskboardKyc,
                                        }),
                                    },
                                },

                                {
                                    path: 'taskboard-deposits',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'tasks.taskboard.taskboardDeposits'
                                            }
                                        >
                                            <AdminTaskboardDeposits />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('taskboardDeposits'),
                                            path: adminPaths.taskboardDeposits,
                                        }),
                                    },
                                },
                                {
                                    path: 'taskboard-withdrawals',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'tasks.taskboard.taskboardWithdrawals'
                                            }
                                        >
                                            <AdminTaskboardWithdrawals />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('taskboardWithdrawals'),
                                            path: adminPaths.taskboardWithdrawals,
                                        }),
                                    },
                                },
                                {
                                    path: 'taskboard-password',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'tasks.taskboard.taskboardPassword'
                                            }
                                        >
                                            <AdminTaskboardPassword />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('taskboardPassword'),
                                            path: adminPaths.taskboardPassword,
                                        }),
                                    },
                                },
                                {
                                    path: 'taskboard-leverage',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'tasks.taskboard.taskboardLeverage'
                                            }
                                        >
                                            <AdminTaskboardLeverage />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('taskboardLeverage'),
                                            path: adminPaths.taskboardLeverage,
                                        }),
                                    },
                                },
                                {
                                    path: 'taskboard-transfer',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'tasks.taskboard.taskboardTransfer'
                                            }
                                        >
                                            <AdminTaskboardTransfer />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('taskboardTransfer'),
                                            path: adminPaths.taskboardTransfer,
                                        }),
                                    },
                                },
                                {
                                    path: 'taskboard-general',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'tasks.taskboard.taskboardGeneral'
                                            }
                                        >
                                            <AdminTaskboardGeneral />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('taskboardGeneral'),
                                            path: adminPaths.taskboardGeneral,
                                        }),
                                    },
                                },
                            ],
                        },
                        // {
                        //     path: 'approvals',
                        //     handle: {
                        //         crumb: ({data, i18n}) => ({
                        //             label: i18n('approvals'),
                        //             path: adminPaths.approvals,
                        //         }),
                        //     },
                        //     element: (
                        //         <AdminPermissionGuard
                        //             permissionKey={'tasks.approvals'}
                        //         >
                        //             <ApprovalsLayout />
                        //         </AdminPermissionGuard>
                        //     ),
                        //     children: [
                        //         {
                        //             element: (
                        //                 <Navigate
                        //                     to={
                        //                         adminPaths.approvalsMt45Accounts
                        //                     }
                        //                     replace
                        //                 />
                        //             ),
                        //             index: true,
                        //         },
                        //         {
                        //             path: 'approvals-mt45-accounts',
                        //             element: (
                        //                 <AdminPermissionGuard
                        //                     permissionKey={
                        //                         'tasks.approvals.approvalsMt45Accounts'
                        //                     }
                        //                 >
                        //                     <AdminApprovalsMt45Accounts />
                        //                 </AdminPermissionGuard>
                        //             ),
                        //             handle: {
                        //                 crumb: ({params, i18n}) => ({
                        //                     label: i18n(
                        //                         'approvalsMt45Accounts',
                        //                     ),
                        //                     path: adminPaths.approvalsMt45Accounts,
                        //                 }),
                        //             },
                        //         },
                        //         {
                        //             path: 'approvals-ib-applications',
                        //             element: (
                        //                 <AdminPermissionGuard
                        //                     permissionKey={
                        //                         'tasks.approvals.approvalsIbApplications'
                        //                     }
                        //                 >
                        //                     <AdminApprovalsIbApplications />
                        //                 </AdminPermissionGuard>
                        //             ),
                        //             handle: {
                        //                 crumb: ({params, i18n}) => ({
                        //                     label: i18n(
                        //                         'approvalsIbApplications',
                        //                     ),
                        //                     path: adminPaths.approvalsIbApplications,
                        //                 }),
                        //             },
                        //         },
                        //         {
                        //             path: 'approvals-fx-withdrawals',
                        //             element: (
                        //                 <AdminPermissionGuard
                        //                     permissionKey={
                        //                         'tasks.approvals.approvalsFxWithdrawals'
                        //                     }
                        //                 >
                        //                     <AdminApprovalsFxWithdrawals />
                        //                 </AdminPermissionGuard>
                        //             ),
                        //             handle: {
                        //                 crumb: ({params, i18n}) => ({
                        //                     label: i18n(
                        //                         'approvalsFxWithdrawals',
                        //                     ),
                        //                     path: adminPaths.approvalsFxWithdrawals,
                        //                 }),
                        //             },
                        //         },
                        //     ],
                        // },
                    ],
                },
                {
                    path: 'risk-management',
                    element: (
                        <AdminPermissionGuard
                            permissionKey={'riskManagement'}
                        />
                    ),
                    children: [
                        {
                            path: 'approvals',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('approvals'),
                                    path: adminPaths.approvals,
                                }),
                            },
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'riskManagement.approvals'}
                                >
                                    <ApprovalsLayout />
                                </AdminPermissionGuard>
                            ),
                            children: [
                                {
                                    element: (
                                        <Navigate
                                            to={adminPaths.approvalsKyc}
                                            replace
                                        />
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'approvals-kyc',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'riskManagement.approvals.approvalsKyc'
                                            }
                                        >
                                            <AdminApprovalsKyc />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('kyc_request'),
                                            path: adminPaths.approvalsKyc,
                                        }),
                                    },
                                },
                                {
                                    path: 'approvals-mt45-accounts',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'riskManagement.approvals.approvalsMt45Accounts'
                                            }
                                        >
                                            <AdminApprovalsMt45Accounts />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n(
                                                'approvalsMt45Accounts',
                                            ),
                                            path: adminPaths.approvalsMt45Accounts,
                                        }),
                                    },
                                },
                                {
                                    path: 'approvals-ib-applications',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'riskManagement.approvals.approvalsIbApplications'
                                            }
                                        >
                                            <AdminApprovalsIbApplications />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n(
                                                'approvalsIbApplications',
                                            ),
                                            path: adminPaths.approvalsIbApplications,
                                        }),
                                    },
                                },
                                {
                                    path: 'approvals-fx-withdrawals',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'riskManagement.approvals.approvalsFxWithdrawals'
                                            }
                                        >
                                            <AdminApprovalsFxWithdrawals />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n(
                                                'approvalsFxWithdrawals',
                                            ),
                                            path: adminPaths.approvalsFxWithdrawals,
                                        }),
                                    },
                                },
                                {
                                    path: 'approvals-withdrawal-account',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'riskManagement.approvals.approvalsWithdrawalAccount'
                                            }
                                        >
                                            <AdminApprovalsWithdrawalAccount />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n(
                                                'taskboardWithdrawalAccount',
                                            ),
                                            path: adminPaths.taskboardWithdrawalAccount,
                                        }),
                                    },
                                },
                                {
                                    path: 'approvals-withdrawal-wallet',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'riskManagement.approvals.approvalsWithdrawalWallet'
                                            }
                                        >
                                            <AdminApprovalsWithdrawalWallet />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n(
                                                'requestWithdrawalWallet',
                                            ),
                                            path: adminPaths.approvalsWithdrawalWallet,
                                        }),
                                    },
                                },
                                {
                                    path: 'approvals-exchange-withdrawal',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'riskManagement.approvals.approvalsWithdrawalWallet'
                                            }
                                        >
                                            <AdminApprovalsExchangeWithdrawal />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n(
                                                'requestExchangeWithdrawal',
                                            ),
                                            path: adminPaths.approvalsExchangeWithdrawal,
                                        }),
                                    },
                                },
                                {
                                    path: 'approvals-leverage',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'riskManagement.approvals.approvalsWithdrawalWallet'
                                            }
                                        >
                                            <AdminApprovalsLeverage />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n(
                                                'requestWithdrawalWallet',
                                            ),
                                            path: adminPaths.approvalsWithdrawalWallet,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'risk-reports',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'riskManagement.riskReports'}
                                >
                                    <RiskReportsLayout />
                                </AdminPermissionGuard>
                            ),
                            children: [
                                {
                                    element: (
                                        <Navigate
                                            to={adminPaths.salesReports}
                                            replace
                                        />
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'sales-reports',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'riskManagement.riskReports.salesReports'
                                            }
                                        >
                                            <AdminSalesReports />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('salesReports'),
                                            path: adminPaths.salesReports,
                                        }),
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'risk-report',
                    children: [
                        {
                            path: 'mt-monitor',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.riskMtTradeMonitor.total'
                                    }
                                >
                                    <RiskMtMonitorTotal />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'profits-analysis',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.profitsAnalysis'
                                    }
                                >
                                    <ProfitsAnalysis />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'commission-analysis',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.commissionAnalysis'
                                    }
                                >
                                    <CommissionAnalysis />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'closed-monitor',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.riskMtTradeMonitor.total'
                                    }
                                >
                                    <RiskClosedMtMonitorTotal />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'high-gain',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.riskMtTradeMonitor.total'
                                    }
                                >
                                    <HighGainAccountAnalysis />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'low-gain',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.riskMtTradeMonitor.total'
                                    }
                                >
                                    <LowGainAccountAnalysis />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'profit-over-currency',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.riskMtTradeMonitor.total'
                                    }
                                >
                                    <ProfitOverCurrency />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'closed-in-second',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.riskMtTradeMonitor.total'
                                    }
                                >
                                    <ClosedInSencond />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'open-in-second',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.riskMtTradeMonitor.total'
                                    }
                                >
                                    <OpenInSencond />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'daily-profit-over-currency',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.riskMtTradeMonitor.total'
                                    }
                                >
                                    <DailyProfitOverCurrency />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'profitable-trades-closed',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.riskMtTradeMonitor.total'
                                    }
                                >
                                    <ProfitableTradesClosedInSencond />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'daily-profit-over-perc',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.riskMtTradeMonitor.total'
                                    }
                                >
                                    <DailyProfitOverPerc />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'account-assessment',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.riskMtTradeMonitor.total'
                                    }
                                >
                                    <AccountAssessment />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'risk-account',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.riskMtTradeMonitor.total'
                                    }
                                >
                                    <RiskAccount />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'equity-reports',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.equityReports'
                                    }
                                >
                                    <EquityReports />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'task-reports',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'riskManagement.taskReports'}
                                >
                                    <TaskReports />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'commission-reports',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'riskManagement.commissionReports'
                                    }
                                >
                                    <CommissionReports />
                                </AdminPermissionGuard>
                            ),
                        },
                    ],
                },
                {
                    path: 'clients',
                    element: (
                        <AdminPermissionGuard permissionKey={'clientsHome'} />
                    ),
                    children: [
                        {
                            path: 'clients',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'clientsHome.clients'}
                                />
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('clients'),
                                    path: adminPaths.clients,
                                }),
                            },
                            children: [
                                {
                                    element: <AdminClients />,
                                    index: true,
                                },
                                {
                                    path: ':id',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'clientsHome.clients.clientDetail'
                                            }
                                        >
                                            <AdminClientDetail />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('clientDetail'),
                                            path: adminPaths.clientDetail(
                                                get(params, 'id'),
                                            ),
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'clients-leads',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'clientsHome.clientsLeads'}
                                >
                                    <AdminLeads />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('clientsLeads'),
                                    path: adminPaths.clientsLeads,
                                }),
                            },
                        },
                        {
                            path: 'clients-ib-admin',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'clientsHome.clientsIbAdmin'}
                                >
                                    <AdminClientsIbAdmin />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('clientsIbAdmin'),
                                    path: adminPaths.clientsIbAdmin,
                                }),
                            },
                        },
                        {
                            path: 'clients-accounts',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'clientsHome.clientsAccounts'
                                    }
                                >
                                    <AdminClientsAccounts />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('clientsAccounts'),
                                    path: adminPaths.clientsAccounts,
                                }),
                            },
                        },
                        {
                            path: 'clients-rewire-accounts',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'clientsHome.clientsRewireAccounts'
                                    }
                                >
                                    <AdminClientsRewireAccount />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('clientsRewireAccounts'),
                                    path: adminPaths.clientsRewireAccounts,
                                }),
                            },
                        },
                        {
                            path: 'clients-pamm-accounts',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'clientsHome.clientsPammAccounts'
                                    }
                                >
                                    <ConfidencialAdmin />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('clientsPammAccounts'),
                                    path: adminPaths.clientsAccounts,
                                }),
                            },
                        },
                        {
                            path: 'clients-cc-sub-accounts',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'clientsHome.clientsCCSubAccounts'
                                    }
                                >
                                    <AdminClientsCCSubaccounts />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('clientsCCSubAccounts'),
                                    path: adminPaths.clientsCCSubAccounts,
                                }),
                            },
                        },
                        {
                            path: 'manager-supports',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'clientsHome.managerSupports'
                                    }
                                >
                                    <AdminManagerSupports />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('managerSupports'),
                                    path: adminPaths.managerSupports,
                                }),
                            },
                        },
                        {
                            path: 'manager-sales',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'clientsHome.managerSales'}
                                >
                                    <AdminManagerSales />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('managerSales'),
                                    path: adminPaths.managerSales,
                                }),
                            },
                        },
                        {
                            path: 'client-withdrawal-banks',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'clientsHome.clientWithdrawalBanks'
                                    }
                                >
                                    <AdminClientWithdrawalAccounts type="bankAccount" />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('clientWithdrawalBanks'),
                                    path: adminPaths.clientWithdrawalBanks,
                                }),
                            },
                        },
                        {
                            path: 'client-withdrawal-wallets',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'clientsHome.clientWithdrawalWallets'
                                    }
                                >
                                    <AdminClientWithdrawalAccounts type="cryptoWallet" />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('clientWithdrawalWallets'),
                                    path: adminPaths.clientWithdrawalWallets,
                                }),
                            },
                        },
                        {
                            path: 'client-deposit-wallets',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'clientsHome.clientDepositWallets'
                                    }
                                >
                                    <AdminClientDepositWallets />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('clientDepositWallets'),
                                    path: adminPaths.clientDepositWallets,
                                }),
                            },
                        },
                        {
                            path: 'client-activities',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'clientsHome.clientDepositWallets'
                                    }
                                >
                                    <AdminClientActivities />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('clientActivities'),
                                    path: adminPaths.clientDepositWallets,
                                }),
                            },
                        },
                        {
                            path: 'demo-accounts',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'clientsHome.demoAccounts'
                                    }
                                >
                                    <AdminDemoAccounts />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('demoAccounts'),
                                    path: adminPaths.clientDepositWallets,
                                }),
                            },
                        },
                    ],
                },
                {
                    path: 'profile',
                    element: <ProfileLayout />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('profile'),
                            path: adminPaths.profile,
                        }),
                    },
                    children: [
                        {
                            element: <Profile />,
                            index: true,
                        },
                        {
                            path: 'preference',
                            element: <Preference />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('preference'),
                                    path: adminPaths.preference,
                                }),
                            },
                        },
                        {
                            path: 'security',
                            element: <Security />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('security'),
                                    path: adminPaths.security,
                                }),
                            },
                        },
                        {
                            path: 'notification',
                            element: <NotificationSetting />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('notification'),
                                    path: adminPaths.notificationSetting,
                                }),
                            },
                        },
                    ],
                },
                {
                    path: 'supports',
                    element: (
                        <AdminPermissionGuard permissionKey={'supports'} />
                    ),
                    children: [
                        {
                            path: 'tickets',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('tickets'),
                                    path: adminPaths.tickets,
                                }),
                            },
                            children: [
                                {
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={'supports.tickets'}
                                        >
                                            <AdminTicketList />
                                        </AdminPermissionGuard>
                                    ),
                                    index: true,
                                },
                                {
                                    path: ':id',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={'supports.tickets'}
                                        >
                                            <AdminTicketDetail />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({data, i18n}) => ({
                                            label: i18n('ticketDetail'),
                                            path: adminPaths.ticketDetail(
                                                get(data, 'id'),
                                            ),
                                        }),
                                    },
                                },
                                {
                                    path: 'newTicket',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={'supports.newTicket'}
                                        >
                                            <AdminTicketDetail />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({data, i18n}) => ({
                                            label: i18n('newTicket'),
                                            path: adminPaths.newTicket,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'faq',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('faq'),
                                    path: adminPaths.faq,
                                }),
                            },
                            children: [
                                {
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={'supports.faq'}
                                        >
                                            <AdminFaq />
                                        </AdminPermissionGuard>
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'editFaq',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={'supports.editFaq'}
                                        >
                                            <EditFaq />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({data, i18n}) => ({
                                            label: i18n('editFaq'),
                                            path: adminPaths.editFaq,
                                        }),
                                    },
                                },
                                {
                                    path: 'createFaq',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={'supports.newFaq'}
                                        >
                                            <NewFaq />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({data, i18n}) => ({
                                            label: i18n('newFaq'),
                                            path: adminPaths.newFaq,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'incidents',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'supports.incidents'}
                                >
                                    <AdminIncidents />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('incidents'),
                                    path: adminPaths.incidents,
                                }),
                            },
                        },
                        {
                            path: 'online-support',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'supports.onlineSupport'}
                                >
                                    <AdminOnlineSupport />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('onlineSupport'),
                                    path: adminPaths.onlineSupport,
                                }),
                            },
                        },
                    ],
                },
                {
                    path: 'platform-settings',
                    element: (
                        <AdminPermissionGuard
                            permissionKey={'platformSettings'}
                        />
                    ),
                    children: [
                        {
                            path: 'general-settings',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'platformSettings.generalSettings'
                                    }
                                >
                                    <GeneralSettingLayout />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({params, i18n}) => ({
                                    label: i18n('generalSettings'),
                                    path: adminPaths.generalSettings,
                                }),
                            },
                            children: [
                                {
                                    element: (
                                        <Navigate
                                            to={adminPaths.systemSettings}
                                            replace
                                        />
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'system-settings',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.generalSettings.systemSettings'
                                            }
                                        >
                                            <AdminSystemSettings />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('systemSettings'),
                                            path: adminPaths.systemSettings,
                                        }),
                                    },
                                },
                                {
                                    path: 'client-policies',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.generalSettings.clientPolicies'
                                            }
                                        >
                                            <AdminClientPolicies />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('clientPolicies'),
                                            path: adminPaths.clientPolicies,
                                        }),
                                    },
                                },
                                {
                                    path: 'ib-settings',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.generalSettings.ibSettings'
                                            }
                                        >
                                            <AdminIBSettings />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('ibSettings'),
                                            path: adminPaths.ibSettings,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'mt4-5-settings',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'platformSettings.mt45Settings'
                                    }
                                >
                                    <Mt45SettingsLayout />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({params, i18n}) => ({
                                    label: i18n('mt45Settings'),
                                    path: adminPaths.mt45Settings,
                                }),
                            },
                            children: [
                                {
                                    element: (
                                        <Navigate
                                            to={adminPaths.mt4Settings}
                                            replace
                                        />
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'mt4-settings',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.mt45Settings.mt4Settings'
                                            }
                                        >
                                            <AdminMT4Settings />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('mt4Settings'),
                                            path: adminPaths.mt4Settings,
                                        }),
                                    },
                                },
                                {
                                    path: 'mt4-demo-settings',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.mt45Settings.mt4DemoSettings'
                                            }
                                        >
                                            <AdminMT4DemoSettings />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('mt4DemoSettings'),
                                            path: adminPaths.mt4Settings,
                                        }),
                                    },
                                },
                                {
                                    path: 'mt5-settings',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.mt45Settings.mt5Settings'
                                            }
                                        >
                                            <AdminMT5Settings />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('mt5Settings'),
                                            path: adminPaths.mt5Settings,
                                        }),
                                    },
                                },
                                {
                                    path: 'mt-settings',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.mt45Settings.settings'
                                            }
                                        >
                                            <AdminMTSettings />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('mtSettings'),
                                            path: adminPaths.mtSettings,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'risk-level',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'platformSettings.riskLevel'}
                                >
                                    <AdminRiskLevel />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({params, i18n}) => ({
                                    label: i18n('riskLevel'),
                                    path: adminPaths.riskLevel,
                                }),
                            },
                        },
                        {
                            path: 'regulation-region',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'platformSettings.regulationRegion'
                                    }
                                >
                                    <RegulationRegionLayout />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({params, i18n}) => ({
                                    label: i18n('regulationRegion'),
                                    path: adminPaths.regulationRegion,
                                }),
                            },
                            children: [
                                {
                                    element: (
                                        <Navigate
                                            to={adminPaths.regulation}
                                            replace
                                        />
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'regulation-region',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.regulationRegion.regulation'
                                            }
                                        >
                                            <AdminRegulationRegion />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('regulation'),
                                            path: adminPaths.regulation,
                                        }),
                                    },
                                },
                                {
                                    path: 'restricted-region',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.regulationRegion.restrictedRegion'
                                            }
                                        >
                                            <AdminRestrictedRegion />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('restrictedRegion'),
                                            path: adminPaths.restrictedRegion,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'currencies',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'platformSettings.currencies'
                                    }
                                >
                                    <CurrenciesLayout />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({params, i18n}) => ({
                                    label: i18n('currencies'),
                                    path: adminPaths.currencies,
                                }),
                            },
                            children: [
                                {
                                    element: (
                                        <Navigate
                                            to={adminPaths.currenciesPlatform}
                                            replace
                                        />
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'currencies-defination',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.currencies.currenciesDefination'
                                            }
                                        >
                                            <AdminCurrenciesDefination />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('currenciesDefination'),
                                            path: adminPaths.currenciesDefination,
                                        }),
                                    },
                                },
                                {
                                    path: 'currencies-platform',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.currencies.currenciesPlatform'
                                            }
                                        >
                                            <AdminCurrenciesPlatform />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('currenciesPlatform'),
                                            path: adminPaths.currenciesPlatform,
                                        }),
                                    },
                                },
                                {
                                    path: 'currencies-accounts',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.currencies.currenciesAccounts'
                                            }
                                        >
                                            <AdminCurrenciesAccounts />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('currenciesAccounts'),
                                            path: adminPaths.currenciesAccounts,
                                        }),
                                    },
                                },
                                {
                                    path: 'currencies-withdrawal',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.currencies.currenciesWithdrawal'
                                            }
                                        >
                                            <AdminCurrenciesWithdrawal />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('currenciesWithdrawal'),
                                            path: adminPaths.currenciesWithdrawal,
                                        }),
                                    },
                                },
                                {
                                    path: 'currencies-service-fee',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.currencies.currenciesServiceFee'
                                            }
                                        >
                                            <AdminCurrenciesServiceFee />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('currenciesServiceFee'),
                                            path: adminPaths.currenciesServiceFee,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'payment-channels',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'platformSettings.paymentChannels'
                                    }
                                >
                                    <PaymentGatewayLayout />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({params, i18n}) => ({
                                    label: i18n('payment-channels'),
                                    path: adminPaths.paymentChannels,
                                }),
                            },
                            children: [
                                {
                                    element: (
                                        <Navigate
                                            to={adminPaths.paymentGateway}
                                            replace
                                        />
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'payment-gateway',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.paymentChannels.paymentGateway'
                                            }
                                        >
                                            <AdminPaymentGatewayThridParty />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('paymentGateway'),
                                            path: adminPaths.paymentGateway,
                                        }),
                                    },
                                },
                                {
                                    path: 'blockchain',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.paymentChannels.blockchainGateway'
                                            }
                                        >
                                            <AdminPaymentGatewayBlockchain />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('blockchain'),
                                            path: adminPaths.blockchainGateway,
                                        }),
                                    },
                                },
                                {
                                    path: 'common-gateway',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.paymentChannels.commonGateway'
                                            }
                                        >
                                            <AdminPaymentGatewayCommon />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('commonGateway'),
                                            path: adminPaths.commonGateway,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'integration',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'platformSettings.integration'
                                    }
                                >
                                    <IntergrationLayout />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({params, i18n}) => ({
                                    label: i18n('integration'),
                                    path: adminPaths.integration,
                                }),
                            },
                            children: [
                                {
                                    element: (
                                        <Navigate
                                            to={adminPaths.KYCProvider}
                                            replace
                                        />
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'kyc-provider',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.integration.KYCProvider'
                                            }
                                        >
                                            <AdminKycProvider />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('kycProvider'),
                                            path: adminPaths.KYCProvider,
                                        }),
                                    },
                                },
                                {
                                    path: 'sms-email-provider',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.integration.SMSEmailProvider'
                                            }
                                        >
                                            <AdminSmsEmailProvider />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('smsEmailProvider'),
                                            path: adminPaths.SMSEmailProvider,
                                        }),
                                    },
                                },
                                {
                                    path: 'messager-provider',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.integration.MessagerProvider'
                                            }
                                        >
                                            <AdminMessagerProvider />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('messagerProvider'),
                                            path: adminPaths.MessagerProvider,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'access-control',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'platformSettings.accessControl'
                                    }
                                >
                                    <AccessControlLayout />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({params, i18n}) => ({
                                    label: i18n('accessControl'),
                                    path: adminPaths.accessControl,
                                }),
                            },
                            children: [
                                {
                                    element: (
                                        <Navigate
                                            to={adminPaths.systemRoles}
                                            replace
                                        />
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'system-roles',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.accessControl.systemRoles'
                                            }
                                        >
                                            <AdminSystemRoles />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('systemRoles'),
                                            path: adminPaths.systemRoles,
                                        }),
                                    },
                                },
                                {
                                    path: 'system-role-access',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.accessControl.systemRoleAccess'
                                            }
                                        >
                                            <AdminSystemRolesAccess />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('systemRoleAccess'),
                                            path: adminPaths.systemRoleAccess,
                                        }),
                                    },
                                },
                                {
                                    path: 'user-autherization',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.accessControl.userAutherization'
                                            }
                                        >
                                            <AdminUserAutherization />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('userAutherization'),
                                            path: adminPaths.userAutherization,
                                        }),
                                    },
                                },
                                {
                                    path: 'admin-access-log',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.accessControl.adminAccessLog'
                                            }
                                        >
                                            <AdminSideAdminAccessLog />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('adminAccessLog'),
                                            path: adminPaths.adminAccessLog,
                                        }),
                                    },
                                },
                                {
                                    path: 'client-access-log',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.accessControl.clientAccessLog'
                                            }
                                        >
                                            <AdminSideAdminAccessLog
                                                clientOnly
                                            />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('clientAccessLog'),
                                            path: adminPaths.clientAccessLog,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'metadata',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'platformSettings.metadata'}
                                >
                                    <MetadataLayout />
                                </AdminPermissionGuard>
                            ),
                            path: 'metadata',
                            handle: {
                                crumb: ({params, i18n}) => ({
                                    label: i18n('metadata'),
                                    path: adminPaths.metadata,
                                }),
                            },
                            children: [
                                {
                                    element: (
                                        <Navigate
                                            to={adminPaths.metadataCategory}
                                            replace
                                        />
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'metadata-category',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.metadata.metadataCategory'
                                            }
                                        >
                                            <AdminMetadataCategory />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('metadataCategory'),
                                            path: adminPaths.metadataCategory,
                                        }),
                                    },
                                },
                                {
                                    path: 'metadata-edit',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.metadata.metadataEdit'
                                            }
                                        >
                                            <AdminMetadataEdit />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('metadataEdit'),
                                            path: adminPaths.metadataEdit,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'system-products',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'platformSettings.systemProducts'
                                    }
                                >
                                    <AdminSystemProduct />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({params, i18n}) => ({
                                    label: i18n('systemProducts'),
                                    path: adminPaths.systemProducts,
                                }),
                            },
                        },
                    ],
                },
                {
                    path: 'application-management',
                    element: (
                        <AdminPermissionGuard
                            permissionKey={'applicationManagement'}
                        />
                    ),
                    children: [
                        {
                            path: 'api-management',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'applicationManagement.apiManagement'
                                    }
                                >
                                    <ApiManagementLayout />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({params, i18n}) => ({
                                    label: i18n('accessControl'),
                                    path: adminPaths.apiManagement,
                                }),
                            },
                            children: [
                                {
                                    element: (
                                        <Navigate
                                            to={adminPaths.apiAutherization}
                                            replace
                                        />
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'api-autherization',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'applicationManagement.apiManagement.apiAutherization'
                                            }
                                        >
                                            <AdminApiAutherization />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('systemRoles'),
                                            path: adminPaths.apiAutherization,
                                        }),
                                    },
                                },
                                {
                                    path: 'api-access-log',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'applicationManagement.apiManagement.apiAccessLog'
                                            }
                                        >
                                            <AdminApiAccessLog />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('apiAccessLog'),
                                            path: adminPaths.apiAccessLog,
                                        }),
                                    },
                                },
                                {
                                    path: 'api-error-tracking',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'applicationManagement.apiManagement.apiErrorTracking'
                                            }
                                        >
                                            <AdminApiErrorTracking />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('apiErrorTracking'),
                                            path: adminPaths.apiErrorTracking,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'system-monitor',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'applicationManagement.systemMonitorHome'
                                    }
                                >
                                    <SystemMonitorLayout />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({params, i18n}) => ({
                                    label: i18n('systemStatus'),
                                    path: adminPaths.systemMonitorHome,
                                }),
                            },
                            children: [
                                {
                                    element: (
                                        <Navigate
                                            to={adminPaths.systemScheduledTasks}
                                            replace
                                        />
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'system-status',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'applicationManagement.systemMonitorHome.systemStatus'
                                            }
                                        >
                                            <AdminSystemMonitor />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('systemStatus'),
                                            path: adminPaths.systemStatus,
                                        }),
                                    },
                                },
                                {
                                    path: 'system-log',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'applicationManagement.systemMonitorHome.systemLog'
                                            }
                                        >
                                            <AdminSystemLog />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('systemLog'),
                                            path: adminPaths.systemLog,
                                        }),
                                    },
                                },
                                {
                                    path: 'system-alert',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'applicationManagement.systemMonitorHome.systemAlert'
                                            }
                                        >
                                            <AdminSystemAlert />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('systemAlert'),
                                            path: adminPaths.systemAlert,
                                        }),
                                    },
                                },
                                {
                                    path: 'system-error-tracking',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'applicationManagement.systemMonitorHome.systemErrorTracking'
                                            }
                                        >
                                            <AdminSystemErrorTracking />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('systemErrorTracking'),
                                            path: adminPaths.systemErrorTracking,
                                        }),
                                    },
                                },
                                {
                                    path: 'system-scheduled-tasks',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'applicationManagement.systemMonitorHome.systemScheduledTasks'
                                            }
                                        >
                                            <AdminSystemScheduledTasks />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('systemScheduledTasks'),
                                            path: adminPaths.systemScheduledTasks,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'security-management',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'applicationManagement.securityManagement'
                                    }
                                >
                                    <SecurityManagementLayout />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({params, i18n}) => ({
                                    label: i18n('securityManagement'),
                                    path: adminPaths.securityManagement,
                                }),
                            },
                            children: [
                                {
                                    element: (
                                        <Navigate
                                            to={adminPaths.ipAccessLog}
                                            replace
                                        />
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'ip-access-log',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'applicationManagement.securityManagement.ipAccessLog'
                                            }
                                        >
                                            <AdminIpAccessLog />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('ipAccessLog'),
                                            path: adminPaths.ipAccessLog,
                                        }),
                                    },
                                },
                                {
                                    path: 'ip-blacklist',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'applicationManagement.securityManagement.ipBlacklist'
                                            }
                                        >
                                            <AdminIpBlacklist />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('ipBlacklist'),
                                            path: adminPaths.ipBlacklist,
                                        }),
                                    },
                                },
                                {
                                    path: 'security-policy',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'applicationManagement.securityManagement.securityPolicy'
                                            }
                                        >
                                            <AdminSecurityPolicy />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('securityPolicy'),
                                            path: adminPaths.securityPolicy,
                                        }),
                                    },
                                },
                                {
                                    path: 'auth-rules',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'applicationManagement.securityManagement.authRules'
                                            }
                                        >
                                            <AdminAuthRules />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('authRules'),
                                            path: adminPaths.authRules,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'bulk-data',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'applicationManagement.bulkData'
                                    }
                                >
                                    <BulkDataLayout />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({params, i18n}) => ({
                                    label: i18n('bulkData'),
                                    path: adminPaths.bulkData,
                                }),
                            },
                            children: [
                                {
                                    element: (
                                        <Navigate
                                            to={adminPaths.clientsBulkData}
                                            replace
                                        />
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'clients-bulk-data',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'applicationManagement.bulkData.clientsBulkData'
                                            }
                                        >
                                            <AdminClientsBulkData />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('clientsBulkData'),
                                            path: adminPaths.clientsBulkData,
                                        }),
                                    },
                                },
                                {
                                    path: 'banks-bulk-data',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'applicationManagement.bulkData.banksBulkData'
                                            }
                                        >
                                            <AdminBanksBulkData />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('banksBulkData'),
                                            path: adminPaths.banksBulkData,
                                        }),
                                    },
                                },
                                {
                                    path: 'transactions-bulk-data',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'applicationManagement.bulkData.tradesBulkData'
                                            }
                                        >
                                            <AdminTradesBulkData />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('tradesBulkData'),
                                            path: adminPaths.tradesBulkData,
                                        }),
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'finance',
                    element: (
                        <AdminPermissionGuard permissionKey={'finance'}>
                            <FinanceLayout />
                        </AdminPermissionGuard>
                    ),
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('finance'),
                            path: adminPaths.finance,
                        }),
                    },
                    children: [
                        {
                            element: (
                                <Navigate
                                    to={adminPaths.financeSummary}
                                    replace
                                />
                            ),
                            index: true,
                        },
                        {
                            path: 'finance-summary',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'finance.financeSummary'}
                                >
                                    <AdminFinanceSummary />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('financeSummary'),
                                    path: adminPaths.financeSummary,
                                }),
                            },
                        },
                        {
                            path: 'finance-transaction',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'finance.financeTransaction'}
                                >
                                    <AdminFinanceTransaction />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('financeTransaction'),
                                    path: adminPaths.financeTransaction,
                                }),
                            },
                        },
                        {
                            path: 'finance-ledge',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'finance.financeLedge'}
                                >
                                    <AdminFinanceLedge />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('financeLedge'),
                                    path: adminPaths.financeLedge,
                                }),
                            },
                        },
                        {
                            path: 'finance-expenses',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'finance.financeExpenses'}
                                >
                                    <AdminFinanceExpenses />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('financeExpenses'),
                                    path: adminPaths.financeExpenses,
                                }),
                            },
                        },
                        {
                            path: 'finance-requests',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'finance.financeRequests'}
                                >
                                    <AdminFinanceRequests />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('financeRequests'),
                                    path: adminPaths.financeRequests,
                                }),
                            },
                        },
                    ],
                },
                {
                    path: 'manage-reports',
                    // element: (
                    //     <AdminPermissionGuard
                    //         permissionKey={
                    //             'manageReports'
                    //         }
                    //     >
                    //         <ManageReportsLayout />
                    //     </AdminPermissionGuard>
                    // ),
                    children: [
                        {
                            element: (
                                <Navigate
                                    to={adminPaths.equityReports}
                                    replace
                                />
                            ),
                            index: true,
                        },
                        {
                            path: 'equity-reports',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'manageReports.equityReports'}
                                >
                                    <EquityReports />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('equityReports'),
                                    path: adminPaths.equityReports,
                                }),
                            },
                        },
                        {
                            path: 'net-reports',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'manageReports.netReports'
                                    }
                                >
                                    <NetReports />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'client-reports',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'manageReports.clientReports'
                                    }
                                >
                                    <ClientReports />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'manage-commission-reports',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'manageReports.manageCommissionReports'
                                    }
                                >
                                    <ManageCommissionReport />
                                </AdminPermissionGuard>
                            ),
                        },
                        {
                            path: 'manage-commission-reports-detail',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'manageReports.manageCommissionReports'
                                    }
                                >
                                    <ManageCommissionReportDetail />
                                </AdminPermissionGuard>
                            ),
                        },
                    ]
                },
                {
                    path: 'information',
                    element: (
                        <AdminPermissionGuard
                            permissionKey={'informationHome'}
                        />
                    ),
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('informationCenter'),
                            path: adminPaths.informationHome,
                        }),
                    },
                    children: [
                        {
                            element: (
                                <Navigate
                                    to={adminPaths.informationEmails}
                                    replace
                                />
                            ),
                            index: true,
                        },
                        {
                            path: 'information-announcements',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'informationHome.informationAnnouncements'
                                    }
                                />
                            ),

                            children: [
                                {
                                    element: <AdminInformationAnnouncements />,
                                    index: true,
                                    handle: {
                                        crumb: ({data, i18n}) => ({
                                            label: i18n(
                                                'informationAnnouncements',
                                            ),
                                            path: adminPaths.informationAnnouncements,
                                        }),
                                    },
                                },
                                {
                                    path: ':id',
                                    element: (
                                        <AdminInformationAnnouncementsDetail />
                                    ),
                                },
                                {
                                    path: 'new-announcement',
                                    element: (
                                        <AdminInformationAnnouncementsDetail />
                                    ),
                                },
                            ],
                        },
                        {
                            path: 'information-news',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'informationHome.informationNews'
                                    }
                                >
                                    <AdminInformationNews />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('informationNews'),
                                    path: adminPaths.informationNews,
                                }),
                            },
                        },
                        {
                            path: 'information-ads',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('informationAds'),
                                    path: adminPaths.informationAds,
                                }),
                            },
                            children: [
                                {
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'informationHome.informationAds'
                                            }
                                        >
                                            <AdminInformationAds />
                                        </AdminPermissionGuard>
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'createAds',
                                    element: <NewAds />,
                                    handle: {
                                        crumb: ({data, i18n}) => ({
                                            label: i18n('createAds'),
                                            path: adminPaths.newAds,
                                        }),
                                    },
                                },
                                {
                                    path: ':id',
                                    element: <EditAds />,
                                    // handle: {
                                    //     crumb: ({data, i18n}) => ({
                                    //         label: i18n('editAds'),
                                    //         path: adminPaths.editAds,
                                    //     }),
                                    // },
                                },
                            ],
                        },
                        {
                            path: 'information-emails',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'informationHome.informationEmails'
                                    }
                                >
                                    <InformationEmailLayout />
                                </AdminPermissionGuard>
                            ),
                            children: [
                                {
                                    index: true,
                                    element: (
                                        <Navigate
                                            to={adminPaths.informationEmails}
                                            replace
                                        />
                                    ),
                                },
                                {
                                    path: 'information-email-list',
                                    children: [
                                        {
                                            element: <AdminInformationEmails />,
                                            index: true,
                                            handle: {
                                                crumb: ({data, i18n}) => ({
                                                    label: i18n(
                                                        'informationEmails',
                                                    ),
                                                    path: adminPaths.informationEmails,
                                                }),
                                            },
                                        },
                                        {
                                            path: ':id',
                                            element: (
                                                <AdminInformationEmailsDetail />
                                            ),
                                        },
                                        {
                                            path: 'new-email',
                                            element: (
                                                <AdminInformationEmailsDetail />
                                            ),
                                        },
                                    ],
                                },
                                {
                                    path: 'information-email-template-list',
                                    children: [
                                        {
                                            element: (
                                                <AdminInformationEmailTemplate />
                                            ),
                                            index: true,
                                            handle: {
                                                crumb: ({data, i18n}) => ({
                                                    label: i18n(
                                                        'informationEmailTemplates',
                                                    ),
                                                    path: adminPaths.informationEmailTemplates,
                                                }),
                                            },
                                        },
                                        {
                                            path: ':id',
                                            element: (
                                                <AdminInformationEmailTemplateDetail />
                                            ),
                                        },
                                        {
                                            path: 'new-template',
                                            element: (
                                                <AdminInformationEmailTemplateDetail />
                                            ),
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: 'information-messages',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'informationHome.informationMessages'
                                    }
                                />
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('informationMessages'),
                                    path: adminPaths.informationMessages,
                                }),
                            },
                            children: [
                                {
                                    element: <AdminInformationMessages />,
                                    index: true,
                                },
                                {
                                    path: ':id',
                                    element: <AdminInformationMessagesDetail />,
                                },
                                {
                                    path: 'new-email',
                                    element: <AdminInformationMessagesDetail />,
                                },
                            ],
                        },
                        {
                            path: 'information-access-source',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'informationHome.informationAccessSource'
                                    }
                                >
                                    <CampaignsLayout />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('informationMessages'),
                                    path: adminPaths.informationCampaigns,
                                }),
                            },
                            children: [
                                {
                                    element: (
                                        <Navigate
                                            to={adminPaths.informationCampaigns}
                                            replace
                                        />
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'campaigns',
                                    element: (
                                        <AdminInformationCampaignsOverview />
                                    ),
                                },
                                {
                                    path: 'campaigns-logs',
                                    element: <AdminInformationAccessSource />,
                                },
                                {
                                    path: 'converted-clients',
                                    element: <AdminInformationConvertedClients />,
                                },
                                {
                                    path: 'registered-clients',
                                    element: <AdminInformationRegisteredClients />,
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'transactions',
                    element: (
                        <AdminPermissionGuard permissionKey={'transactions'}>
                            <TradesLayout />
                        </AdminPermissionGuard>
                    ),
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('transactions'),
                            path: adminPaths.transactions,
                        }),
                    },
                    children: [
                        // {
                        //     element: (
                        //         <Navigate
                        //             to={adminPaths.transactionSummary}
                        //             replace
                        //         />
                        //     ),
                        //     index: true,
                        // },
                        {
                            path: 'transaction-summary',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'transactions.transactionSummary'
                                    }
                                >
                                    <AdminTradesSummary />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('transactionSummary'),
                                    path: adminPaths.transactionSummary,
                                }),
                            },
                        },
                        {
                            path: 'transaction-deposits',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'transactions.transactionDeposits'
                                    }
                                >
                                    <AdminTradesDeposits />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('transactionDeposits'),
                                    path: adminPaths.transactionDeposits,
                                }),
                            },
                        },
                        {
                            path: 'transaction-withdrawals',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'transactions.transactionWithdrawals'
                                    }
                                >
                                    <AdminTradesWithdrawals />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('transactionWithdrawals'),
                                    path: adminPaths.transactionWithdrawals,
                                }),
                            },
                        },
                        {
                            path: 'transaction-transfers',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'transactions.transactionTransfers'
                                    }
                                >
                                    <AdminTradesTransfers />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('transactionTransfers'),
                                    path: adminPaths.transactionTransfers,
                                }),
                            },
                        },
                        {
                            path: 'transaction-commissions',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'transactions.transactionCommissions'
                                    }
                                >
                                    <AdminTradesCommissions />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('transactionCommissions'),
                                    path: adminPaths.transactionCommissions,
                                }),
                            },
                        },
                        {
                            path: 'transaction-adjustments',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'transactions.transactionAdjustments'
                                    }
                                >
                                    <AdminTradesAdjustments />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('transactionAdjustments'),
                                    path: adminPaths.transactionAdjustments,
                                }),
                            },
                        },
                        {
                            path: 'transaction-money-moves',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'transactions.transactionMoneyMoves'
                                    }
                                >
                                    <AdminTradesMoneyMoves />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('transactionMoneyMoves'),
                                    path: adminPaths.transactionMoneyMoves,
                                }),
                            },
                        },
                        {
                            path: 'transaction-HDWallet-operations',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'transactions.transactionHDWalletOperations'
                                    }
                                >
                                    <AdminTradesHDWalletOperations />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n(
                                        'transactionHDWalletOperations',
                                    ),
                                    path: adminPaths.transactionHDWalletOperations,
                                }),
                            },
                        },
                    ],
                },
                {
                    path: 'notification',
                    element: (
                        <AdminPermissionGuard permissionKey={'notification'}>
                            <AdminNotification />
                        </AdminPermissionGuard>
                    ),
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('notification'),
                            path: adminPaths.notification,
                        }),
                    },
                },

                {
                    path: 'stocks',
                    element: (
                        <AdminPermissionGuard permissionKey={'stocksHome'} />
                    ),
                    children: [
                        {
                            path: 'accounts',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('accounts'),
                                    path: adminPaths.stockAccounts,
                                }),
                            },
                            children: [
                                {
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'stocksHome.stockAccounts'
                                            }
                                        >
                                            <AdminStockAccounts />
                                        </AdminPermissionGuard>
                                    ),
                                    index: true,
                                },
                            ],
                        },
                        {
                            path: 'transactions',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('transactions'),
                                    path: adminPaths.stockTransactions,
                                }),
                            },
                            children: [
                                {
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'stocksHome.stockAccounts'
                                            }
                                        >
                                            <AdminStockTransactions />
                                        </AdminPermissionGuard>
                                    ),
                                    index: true,
                                },
                            ],
                        },
                        {
                            path: 'positions',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('positions'),
                                    path: adminPaths.stockPositions,
                                }),
                            },
                            children: [
                                {
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'stocksHome.stockPositions'
                                            }
                                        >
                                            <AdminStockPositions />
                                        </AdminPermissionGuard>
                                    ),
                                    index: true,
                                },
                            ],
                        },
                        {
                            path: 'stock-products',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('stockProducts'),
                                    path: adminPaths.stockProducts,
                                }),
                            },
                            children: [
                                {
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'stocksHome.stockProducts'
                                            }
                                        >
                                            <AdminStockProducts />
                                        </AdminPermissionGuard>
                                    ),
                                    index: true,
                                },
                            ],
                        },
                        {
                            path: 'requests',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('requests'),
                                    path: adminPaths.stockRequests,
                                }),
                            },
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'stocksHome.stockRequests'}
                                >
                                    <StockRequestLayout />
                                </AdminPermissionGuard>
                            ),
                            children: [
                                {
                                    element: (
                                        <Navigate
                                            to={adminPaths.stockRequestSummary}
                                            replace
                                        />
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'summary',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'stocksHome.stockRequests.requestSummary'
                                            }
                                        >
                                            <AdminStockRequests />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('requestSummary'),
                                            path: adminPaths.stockRequestSummary,
                                        }),
                                    },
                                },
                                {
                                    path: 'accounts',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'stocksHome.stockRequests.requestAccount'
                                            }
                                        >
                                            <AdminStockRequests />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('account_applications'),
                                            path: adminPaths.stockRequestAccount,
                                        }),
                                    },
                                },
                                {
                                    path: 'deposits',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'stocksHome.stockRequests.requestDeposits'
                                            }
                                        >
                                            <AdminStockRequests />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('requestDeposits'),
                                            path: adminPaths.stockRequestDeposits,
                                        }),
                                    },
                                },
                                {
                                    path: 'withdrawals',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'stocksHome.stockRequests.requestWithdrawals'
                                            }
                                        >
                                            <AdminStockRequests />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('requestWithdrawals'),
                                            path: adminPaths.stockRequestWithdrawals,
                                        }),
                                    },
                                },
                                {
                                    path: 'transfers',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'stocksHome.stockRequests.requestTransfer'
                                            }
                                        >
                                            <AdminStockRequests />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('requestTransfer'),
                                            path: adminPaths.stockRequestTransfer,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'taskboard',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('taskboard'),
                                    path: adminPaths.stockTaskboard,
                                }),
                            },
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'stocksHome.stockTaskBoard'}
                                >
                                    <StockTaskboardLayout />
                                </AdminPermissionGuard>
                            ),
                            children: [
                                {
                                    element: (
                                        <Navigate
                                            to={
                                                adminPaths.stockTaskboardDeposits
                                            }
                                            replace
                                        />
                                    ),
                                    index: true,
                                },
                                {
                                    path: 'taskboard-deposits',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'stocksHome.stockTaskBoard.taskboardDeposits'
                                            }
                                        >
                                            <AdminTaskboardDeposits
                                                isStock={true}
                                            />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('taskboardDeposits'),
                                            path: adminPaths.stockTaskboardDeposits,
                                        }),
                                    },
                                },
                                {
                                    path: 'taskboard-withdrawals',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'stocksHome.stockTaskBoard.taskboardWithdrawals'
                                            }
                                        >
                                            <AdminTaskboardWithdrawals
                                                isStock={true}
                                            />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('taskboardWithdrawals'),
                                            path: adminPaths.stockTaskboardWithdrawals,
                                        }),
                                    },
                                },
                                {
                                    path: 'taskboard-transfer',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'stocksHome.stockTaskBoard.taskboardTransfer'
                                            }
                                        >
                                            <AdminTaskboardTransfer
                                                isStock={true}
                                            />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({params, i18n}) => ({
                                            label: i18n('taskboardTransfer'),
                                            path: adminPaths.stockTaskboardTransfer,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'my-tasks',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('myTasks'),
                                    path: adminPaths.myStockTasks,
                                }),
                            },
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'stocksHome.myStockTasks'}
                                >
                                    <AdminMyTasks isStock={true} />
                                </AdminPermissionGuard>
                            ),
                        },
                    ],
                },

                {path: 'logout', element: <Logout />},
                {path: 'settings', element: <Settings />},
                {path: '404', element: <Page404 />},
                {path: '403', element: <Page403 />},
                {path: '*', element: <Navigate to="/crm/404" replace />},
            ],
        },
    ];
};

export const RootRouter = () => {
    const authRoute = getAuthRoute();
    const appRoute = getAppRoute();
    const adminRoute = getAdminRoute();
    const edgeCaseRoute = getEdgeCaseRoute();
    const publicRoute = getPublicRoute();
    const devRoute = getDevRoute();
    const authLogout = useAuthStore((state) => state.logout);
    const uiState = useUIStore((state) => state);
    const router = createBrowserRouter(
        [
            ...authRoute,
            ...publicRoute,
            ...edgeCaseRoute,
            ...appRoute,
            ...adminRoute,
            ...devRoute,
        ],
        // {basename: '/client'},
    );

    return (
        <SWRConfig value={{...RNConfig({logout: authLogout, uiState})}}>
            <RouterProvider router={router} />
        </SWRConfig>
    );
    // return <RouterProvider router={router} />;
};

export default RootRouter;

const Loadable = (Component) => (props) => {
    return (
        <Suspense fallback={<LoadingScreen />}>
            <SafeLoadingComponent>
                <Component {...props} />
            </SafeLoadingComponent>
        </Suspense>
    );
};

// const lazyImport = (path) => {
//     console.log(path)
//     return lazy(() =>
//         import().catch((error) => {
//             console.error('Component Failed Loading:', error);
//             return {default: ComponentError};
//         }),
//     );
// }

const Page404 = Loadable(lazy(() => import('page/Page404')));
const Page403 = Loadable(lazy(() => import('page/Page403')));

const Login = Loadable(lazy(() => import('page/auth/OXCustomized/OXLogin')));
const Redirector = Loadable(lazy(() => import('page/Redirector')));
const Maintenance = Loadable(lazy(() => import('page/Maintenance')));

// const Register = Loadable(lazy(() => import('pages/Register')));
// const SignUpVerify = Loadable(lazy(() => import('pages/SignUpVerify')));
const ForgotPassword = Loadable(lazy(() => import('page/auth/OXCustomized/OXForgotPassword')));
const ResetPassword = Loadable(
    lazy(() => import('page/auth/OXCustomized/OXResetPassword')),
);
const Register = Loadable(
    lazy(() => import('page/auth/OXCustomized/OXRegister')),
);
// const Register = Loadable(lazyImport('page/auth/Register'));
const SignUpVerify = Loadable(lazy(() => import('page/auth/OXCustomized/OXSignUpVerify')));
const SignUpLinkVerify = Loadable(
    lazy(() => import('page/auth/OXCustomized/OXSignUpLinkVerify')),
);

//app pages
const Dashboard = Loadable(lazy(() => import('page/app/Dashboard')));
const KYC = Loadable(lazy(() => import('page/app/KYC')));
const KYB = Loadable(lazy(() => import('page/app/KYB')));
const Support = Loadable(lazy(() => import('page/app/Support')));
const SupportDetail = Loadable(lazy(() => import('page/app/SupportDetail')));
const QAndA = Loadable(lazy(() => import('page/app/QAndA')));
const API = Loadable(lazy(() => import('page/app/API')));
const APILogs = Loadable(lazy(() => import('page/app/APILogs')));
const APIKey = Loadable(lazy(() => import('page/app/APIKey')));
const Settings = Loadable(lazy(() => import('page/app/Settings')));
const Profile = Loadable(lazy(() => import('page/app/Profile/Profile')));
const ProfileKYCReview = Loadable(
    lazy(() => import('page/app/Profile/ProfileKYCReview')),
);
const NotificationSetting = Loadable(
    lazy(() => import('page/app/Profile/Notifications')),
);
const ProfileCryptoWalletSetting = Loadable(
    lazy(() => import('page/app/Profile/ProfileCryptoWalletSetting')),
);
const Preference = Loadable(lazy(() => import('page/app/Profile/Preference')));
const Security = Loadable(lazy(() => import('page/app/Profile/Security')));

const Components = Loadable(lazy(() => import('page/Components')));

const Referral = Loadable(lazy(() => import('page/app/Referral/Referral')));
const Commission = Loadable(lazy(() => import('page/app/Referral/Commission')));

const Logout = Loadable(lazy(() => import('page/Logout')));

const Deposit = Loadable(
    lazy(() => import('page/app/Funding/Deposit/Deposit')),
);
const NewDeposit = Loadable(
    lazy(() => import('page/app/Funding/Deposit/NewDeposit')),
);

const ChannelDepositCompletePage = Loadable(
    lazy(() => import('page/app/Funding/Deposit/ChannelDepositCompletePage')),
);
const ChannelDepositCancelPage = Loadable(
    lazy(() => import('page/app/Funding/Deposit/ChannelDepositCancelPage')),
);

const Withdrawal = Loadable(
    lazy(() => import('page/app/Funding/Withdrawal/Withdrawal')),
);
const CreatWithdrawal = Loadable(
    lazy(() => import('page/app/Funding/Withdrawal/CreatWithdrawal')),
);

const WithdrawalSuccess = Loadable(
    lazy(() => import('page/app/Funding/Withdrawal/WithdrawalSuccess')),
);

const Transfer = Loadable(
    lazy(() => import('page/app/Funding/Transfer/TransferHistory')),
);
const CreatTransfer = Loadable(
    lazy(() => import('page/app/Funding/Transfer/Transfer')),
);
const TransferSuccess = Loadable(
    lazy(() => import('page/app/Funding/Transfer/TransferSuccess')),
);

const FundingSummary = Loadable(
    lazy(() => import('page/app/Funding/Summary/Summary')),
);

const FundingCommissions = Loadable(
    lazy(() => import('page/app/Funding/Commissions/Commissions')),
);

const FundingMoneyMove = Loadable(
    lazy(() => import('page/app/Funding/moneyMove/moneyMove')),
);

const FundingWithdrawalAccounts = Loadable(
    lazy(() => import('page/app/Funding/Withdrawal/WithdrawalAccountsPage')),
);
const FundingStatements = Loadable(
    lazy(() => import('page/app/Funding/Statements/Statements')),
);

const Accounts = Loadable(lazy(() => import('page/app/Account/Account')));


const Stock = Loadable(lazy(() => import('page/app/Stock/Stock')));
const StockSummary = Loadable(
    lazy(() => import('page/app/Stock/Summary/StockSummary')),
);
const StockAccount = Loadable(
    lazy(() => import('page/app/Stock/Account/Account')),
);
const StockPosition = Loadable(
    lazy(() => import('page/app/Stock/OpenPositions')),
);
const StockTransaction = Loadable(
    lazy(() => import('page/app/Stock/StockTransactions')),
);
const StockHistory = Loadable(
    lazy(() => import('page/app/Stock/History')),
);
const StockStatement = Loadable(
    lazy(() => import('page/app/Stock/Statements')),
);
const StockLiveSubscriptions = Loadable(
    lazy(() => import('page/app/Stock/Subscription/LiveSubscriptions')),
);
const StockProducts = Loadable(
    lazy(() => import('page/app/Stock/StockCFDProducts')),
);


const IbAgent = Loadable(lazy(() => import('page/app/IbAgent')));

const IBOverview = Loadable(lazy(() => import('page/app/IB/Overview')));

const IBSublevels = Loadable(lazy(() => import('page/app/IB/Sublevels')));

const IBReports = Loadable(lazy(() => import('page/app/IB/IBReports')));

const ClientWiredAccount = Loadable(lazy(() => import('page/app/IB/WiredAccount')));

const PendingInvitations = Loadable(
    lazy(() => import('page/app/IB/PendingInvitations')),
);

const IBAgent = Loadable(lazy(() => import('page/app/IbAgent')));

const News = Loadable(lazy(() => import('page/app/News/News')));
const Announcements = Loadable(
    lazy(() => import('page/app/News/Announcements')),
);

const Tools = Loadable(lazy(() => import('page/app/Tools')));

const Help = Loadable(lazy(() => import('page/app/Help/HelpPage')));
const GuestHelp = Loadable(lazy(() => import('page/auth/GuestHelp')));

const AboutUs = Loadable(lazy(() => import('page/app/AboutUs')));

const Notification = Loadable(lazy(() => import('page/app/Notification')));

//admin pages
const AdminDashboard = Loadable(lazy(() => import('page/admin/Dashboard')));
const AdminOpensearchDashboard = Loadable(lazy(() => import('page/admin/OpensearchDashboard')));
const AdminProfile = Loadable(lazy(() => import('page/admin/Profile')));    

//clients
const AdminClients = Loadable(lazy(() => import('page/admin/Clients/Clients')));
const AdminClientDetail = Loadable(
    lazy(() => import('page/admin/Clients/ClientDetail')),
);
const WithAccount = Loadable(lazy(() => import('page/admin/withAccount')));

const AdminLeads = Loadable(lazy(() => import('page/admin/Clients/Leads')));
const AdminClientsIbAdmin = Loadable(
    lazy(() => import('page/admin/Clients/IbAdmin')),
);
const AdminClientsAccounts = Loadable(
    lazy(() => import('page/admin/Clients/Accounts')),
);
const AdminClientsPammAccounts = Loadable(
    lazy(() => import('page/admin/Clients/Accounts')),
);
const AdminClientsRewireAccount = Loadable(
    lazy(() => import('page/admin/Clients/RewireAccounts')),
);
const AdminClientsCCSubaccounts = Loadable(
    lazy(() => import('page/admin/Clients/CCVAccount')),
);

const AdminStockAccounts = Loadable(
    lazy(() => import('page/admin/Stocks/StockAccounts')),
);

const AdminStockTransactions= Loadable(
    lazy(() => import('page/admin/Stocks/StockTransactions')),
);

const AdminStockPositions= Loadable(
    lazy(() => import('page/admin/Stocks/StockPositions')),
);

const AdminStockProducts  = Loadable(
    lazy(() => import('page/admin/Stocks/StockProducts')),
);

const AdminStockRequests = Loadable(lazy(() => import('page/admin/Stocks/StockRequests')));

const AdminManagerSupports = Loadable(
    lazy(() => import('page/admin/Clients/ManagerSupports')),
);
const AdminManagerSales = Loadable(
    lazy(() => import('page/admin/Clients/ManagerSales')),
);
const AdminClientWithdrawalAccounts = Loadable(
    lazy(() => import('page/admin/Clients/WithdrawalAccounts')),
);
const AdminClientDepositWallets = Loadable(
    lazy(() => import('page/admin/Clients/AdminClientDepositWallets')),
);

const AdminClientActivities = Loadable(
    lazy(() => import('page/admin/Clients/AdminClientActivities')),
);
const AdminDemoAccounts = Loadable(
    lazy(() => import('page/admin/Clients/DemoAccounts')),
);

// metadata
const AdminMetadataCategory = Loadable(
    lazy(() => import('page/admin/PlatformSettings/MetadataCategory')),
);
const AdminMetadataEdit = Loadable(
    lazy(() => import('page/admin/PlatformSettings/MetadataEdit')),
);

//currencies

const AdminCurrenciesDefination = Loadable(
    lazy(() => import('page/admin/PlatformSettings/Currencies')),
);
const AdminCurrenciesPlatform = Loadable(
    lazy(() => import('page/admin/PlatformSettings/CurrenciesPlatform')),
);
const AdminCurrenciesAccounts = Loadable(
    lazy(() => import('page/admin/PlatformSettings/CurrenciesAccounts')),
);
const AdminCurrenciesWithdrawal = Loadable(
    lazy(() => import('page/admin/PlatformSettings/CurrenciesWithdrawal')),
);
const AdminCurrenciesServiceFee = Loadable(
    lazy(() => import('page/admin/PlatformSettings/CurrenciesServiceFee')),
);
// const AdminPlatformMetaData = Loadable(
//     lazy(() => import('page/admin/PlatformSettings/PlatformMetaData')),
// );

// payment
const AdminPaymentGatewayThridParty = Loadable(
    lazy(() => import('page/admin/PlatformSettings/PaymentGatewayThirdParty')),
);
const AdminPaymentGatewayBlockchain = Loadable(
    lazy(() => import('page/admin/PlatformSettings/PaymentGatewayBlockchain')),
);
const AdminPaymentGatewayCommon = Loadable(
    lazy(() => import('page/admin/PlatformSettings/PaymentGatewayCommon')),
);

//general settings
const AdminSystemSettings = Loadable(
    lazy(() => import('page/admin/PlatformSettings/SystemSettings')),
);
const AdminClientPolicies = Loadable(
    lazy(() => import('page/admin/PlatformSettings/ClientPolicies')),
);
const AdminIBSettings = Loadable(
    lazy(() => import('page/admin/PlatformSettings/IBSettings')),
);

// mt45 settings
// const AdminMT45Settings = Loadable(
//     lazy(() => import('page/admin/PlatformSettings/Mt45Settings')),
// );
const AdminMT4Settings = Loadable(
    lazy(() => import('page/admin/PlatformSettings/Mt4Settings')),
);
const AdminMT4DemoSettings = Loadable(
    lazy(() => import('page/admin/PlatformSettings/Mt4DemoSettings')),
);
const AdminMT5Settings = Loadable(
    lazy(() => import('page/admin/PlatformSettings/Mt5Settings')),
);
const AdminMTSettings = Loadable(
    lazy(() => import('page/admin/PlatformSettings/MtSettings')),
);
const AdminRiskLevel = Loadable(
    lazy(() => import('page/admin/PlatformSettings/RiskLevel')),
);
const AdminRegulationRegion = Loadable(
    lazy(() => import('page/admin/PlatformSettings/Regulation')),
);
const AdminRestrictedRegion = Loadable(
    lazy(() => import('page/admin/PlatformSettings/RestrictedRegion')),
);

const AdminSystemProduct = Loadable(
    lazy(() => import('page/admin/PlatformSettings/SystemProduct')),
);

//integration
const AdminKycProvider = Loadable(
    lazy(() => import('page/admin/PlatformSettings/KYCProvider')),
);
const AdminSmsEmailProvider = Loadable(
    lazy(() => import('page/admin/PlatformSettings/SMSEmailProvider')),
);
const AdminMessagerProvider = Loadable(
    lazy(() => import('page/admin/PlatformSettings/MessagerProvider')),
);

//access control
const AdminSystemRoles = Loadable(
    lazy(() => import('page/admin/PlatformSettings/SystemRoles')),
);
const AdminSystemRolesAccess = Loadable(
    lazy(() => import('page/admin/PlatformSettings/SystemRolesAccess')),
);
const AdminUserAutherization = Loadable(
    lazy(() => import('page/admin/PlatformSettings/UserAutherization')),
);
const AdminSideAdminAccessLog = Loadable(
    lazy(() => import('page/admin/PlatformSettings/AdminAccessLog')),
);

//applicaion management
const AdminApiAutherization = Loadable(
    lazy(() => import('page/admin/ApplicationManagement/ApiAutherization')),
);
const AdminApiAccessLog = Loadable(
    lazy(() => import('page/admin/ApplicationManagement/ApiAccessLog')),
);
const AdminApiErrorTracking = Loadable(
    lazy(() => import('page/admin/ApplicationManagement/ApiErrorTracking')),
);

// system monitor
const AdminSystemMonitor = Loadable(
    lazy(() => import('page/admin/ApplicationManagement/SystemMonitor')),
);
const AdminSystemLog = Loadable(
    lazy(() => import('page/admin/ApplicationManagement/SystemLog')),
);
const AdminSystemAlert = Loadable(
    lazy(() => import('page/admin/ApplicationManagement/SystemAlert')),
);
const AdminSystemErrorTracking = Loadable(
    lazy(() => import('page/admin/ApplicationManagement/SystemErrorTracking')),
);
const AdminSystemScheduledTasks = Loadable(
    lazy(() => import('page/admin/ApplicationManagement/SystemScheduledTasks')),
);

//security management

const AdminIpAccessLog = Loadable(
    lazy(() => import('page/admin/ApplicationManagement/IpAccessLog')),
);
const AdminIpBlacklist = Loadable(
    lazy(() => import('page/admin/ApplicationManagement/IpBlacklist')),
);
const AdminSecurityPolicy = Loadable(
    lazy(() => import('page/admin/ApplicationManagement/SecurityPolicy')),
);
const AdminAuthRules = Loadable(
    lazy(() => import('page/admin/ApplicationManagement/AuthRules')),
);

//bulk data
const AdminClientsBulkData = Loadable(
    lazy(() => import('page/admin/ApplicationManagement/ClientsBulkData')),
);
const AdminBanksBulkData = Loadable(
    lazy(() => import('page/admin/ApplicationManagement/BanksBulkData')),
);
const AdminTradesBulkData = Loadable(
    lazy(() => import('page/admin/ApplicationManagement/TradesBulkData')),
);

//finance
const AdminFinanceSummary = Loadable(
    lazy(() => import('page/admin/Finance/FinanceSummary')),
);
const AdminFinanceTransaction = Loadable(
    lazy(() => import('page/admin/Finance/FinanceTransaction')),
);
const AdminFinanceLedge = Loadable(
    lazy(() => import('page/admin/Finance/FinanceLedge')),
);
const AdminFinanceExpenses = Loadable(
    lazy(() => import('page/admin/Finance/FinanceExpenses')),
);
const AdminFinanceRequests = Loadable(
    lazy(() => import('page/admin/Finance/FinanceRequests')),
);

//Admin information

const AdminInformationAnnouncements = Loadable(
    lazy(() => import('page/admin/Information/Announcements')),
);
const AdminInformationAnnouncementsDetail = Loadable(
    lazy(() => import('page/admin/Information/AnnouncementsDetail')),
);
const AdminInformationNews = Loadable(
    lazy(() => import('page/admin/Information/News')),
);
const AdminInformationAds = Loadable(
    lazy(() => import('page/admin/Information/Ads')),
);
const NewAds = Loadable(lazy(() => import('page/admin/Information/CreateAds')));
const EditAds = Loadable(lazy(() => import('page/admin/Information/EditAds')));

const AdminInformationEmails = Loadable(
    lazy(() => import('page/admin/Information/Emails')),
);
const AdminInformationMessagesDetail = Loadable(
    lazy(() => import('page/admin/Information/MessagesDetail')),
);
const AdminInformationEmailsDetail = Loadable(
    lazy(() => import('page/admin/Information/EmailDetail')),
);
const AdminInformationMessages = Loadable(
    lazy(() => import('page/admin/Information/Messages')),
);

const AdminInformationEmailTemplate = Loadable(
    lazy(() => import('page/admin/Information/EmailTemplates')),
);
const AdminInformationEmailTemplateDetail = Loadable(
    lazy(() => import('page/admin/Information/EmailTemplateDetail')),
);

const AdminInformationAccessSource = Loadable(
    lazy(() => import('page/admin/Information/AccessSource')),
);
const AdminInformationCampaignsOverview = Loadable(
    lazy(() =>
        import('page/admin/Information/CampaignsOverview'),
    ),
);
const AdminInformationConvertedClients = Loadable(
    lazy(() => import('page/admin/Information/ConvertedClients')),
);
const AdminInformationRegisteredClients = Loadable(
    lazy(() => import('page/admin/Information/RegisteredClients')),
);
//supports
const AdminFaq = Loadable(lazy(() => import('page/admin/Supports/FaqPage')));
const AdminIncidents = Loadable(
    lazy(() => import('page/admin/Supports/Incidents')),
);
const AdminOnlineSupport = Loadable(
    lazy(() => import('page/admin/Supports/OnlineSupport')),
);
const EditFaq = Loadable(lazy(() => import('page/admin/Supports/EditFaq')));
const NewFaq = Loadable(lazy(() => import('page/admin/Supports/NewFaq')));

//admin tickets
const AdminTicketList = Loadable(
    lazy(() => import('page/admin/Tickets/TicketsList')),
);
const AdminTicketDetail = Loadable(
    lazy(() => import('page/admin/Tickets/TicketDetail')),
);

// admin tasks
// const ClientRequest = Loadable(lazy(() => import('page/admin/clientRequest')));
// const PendingTasks = Loadable(lazy(() => import('page/admin/tasks/Task')));
// const Approval = Loadable(lazy(() => import('page/admin/tasks/Approval')));

// // products
// const Products = Loadable(lazy(() => import('page/admin/product/market')));

// // lead lists
// const LeadList = Loadable(lazy(() => import('page/admin/Lead/LeadList')));
// // forex account
// const FxAccount = Loadable(lazy(() => import('page/admin/fxAccount')));
// // trades
// const TradeSummary = Loadable(lazy(() => import('page/admin/trades/summary')));
// const TradeDeposit = Loadable(lazy(() => import('page/admin/trades/Deposit')));
// const TradeWithdrawal = Loadable(
//     lazy(() => import('page/admin/trades/Withdrawal')),
// );
// const TradeTransfers = Loadable(
//     lazy(() => import('page/admin/trades/Transfers')),
// );
// const TradeExchanges = Loadable(
//     lazy(() => import('page/admin/trades/Exchange')),
// );
// const TradeCommissions = Loadable(
//     lazy(() => import('page/admin/trades/Commissions')),
// );
// // setting
// const ForexGroups = Loadable(
//     lazy(() => import('page/admin/PlatformSettings/forex/DefaultGroups')),
// );
// const CommissionPolicies = Loadable(
//     lazy(() => import('page/admin/PlatformSettings/CommissionPolicies')),
// );
//trades
const AdminTradesSummary = Loadable(
    lazy(() => import('page/admin/Trades/TradesSummary')),
);
const AdminTradesDeposits = Loadable(
    lazy(() => import('page/admin/Trades/TradesDeposits')),
);
const AdminTradesWithdrawals = Loadable(
    lazy(() => import('page/admin/Trades/TradesWithdrawals')),
);
const AdminTradesTransfers = Loadable(
    lazy(() => import('page/admin/Trades/TradesTransfers')),
);
const AdminTradesCommissions = Loadable(
    lazy(() => import('page/admin/Trades/TradesCommissions')),
);
const AdminTradesAdjustments = Loadable(
    lazy(() => import('page/admin/Trades/TradesAdjustments')),
);

const AdminTradesMoneyMoves = Loadable(
    lazy(() => import('page/admin/Trades/TradesMoneyMoves')),
);

const AdminTradesHDWalletOperations = Loadable(
    lazy(() => import('page/admin/Trades/TradesHDWalletOperations')),
);

const AdminRequests = Loadable(lazy(() => import('page/admin/Tasks/Requests')));
const AdminMyTasks = Loadable(lazy(() => import('page/admin/Tasks/MyTasks')));
const AdminTaskboardKYC = Loadable(
    lazy(() => import('page/admin/Tasks/TaskboardKYC')),
);
const AdminTaskboardWithdrawalAccount = Loadable(
    lazy(() => import('page/admin/Tasks/TaskboardWithdrawalAccount')),
);
const AdminTaskboardDeposits = Loadable(
    lazy(() => import('page/admin/Tasks/TaskboardDeposits')),
);
const AdminTaskboardWithdrawals = Loadable(
    lazy(() => import('page/admin/Tasks/TaskboardWithdrawals')),
);
const AdminTaskboardGeneral = Loadable(
    lazy(() => import('page/admin/Tasks/TaskboardGeneral')),
);
const AdminTaskboardPassword = Loadable(
    lazy(() => import('page/admin/Tasks/TaskboardPasswordChange')),
);
const AdminTaskboardLeverage = Loadable(
    lazy(() => import('page/admin/Tasks/TaskboardLeverageChange')),
);
const AdminTaskboardTransfer = Loadable(
    lazy(() => import('page/admin/Tasks/TaskboardTransfer')),
);

const AdminApprovalsMt45Accounts = Loadable(
    lazy(() => import('page/admin/Approvals/ApprovalsMt45Accounts')),
);
const AdminApprovalsIbApplications = Loadable(
    lazy(() => import('page/admin/Approvals/ApprovalsIbApplications')),
);
const AdminApprovalsFxWithdrawals = Loadable(
    lazy(() => import('page/admin/Approvals/ApprovalsFxWithdrawals')),
);
const AdminApprovalsWithdrawalAccount = Loadable(
    lazy(() => import('page/admin/Approvals/ApprovalsWithdrawalAccount')),
);
const AdminApprovalsWithdrawalWallet = Loadable(
    lazy(() => import('page/admin/Approvals/ApprovalsWithdrawalWallet')),
);
const AdminApprovalsExchangeWithdrawal = Loadable(
    lazy(() => import('page/admin/Approvals/AdminApprovalsExchangeWithdrawal')),
);
const AdminApprovalsLeverage = Loadable(
    lazy(() => import('page/admin/Approvals/AdminApprovalsLeverage')),
);
const AdminApprovalsKyc = Loadable(
    lazy(() => import('page/admin/Approvals/ApprovalsKyc')),
);

//Admin risk management

const AdminSalesReports = Loadable(
    lazy(() => import('page/admin/RiskManagement/RiskReports/SalesReports')),
);

const RiskMtMonitorTotal = Loadable(
    lazy(() => import('page/admin/RiskManagement/RiskMtMonitor/total')),
);
const RiskClosedMtMonitorTotal = Loadable(
    lazy(() => import('page/admin/RiskManagement/ClosedMtmonitor/total')),
);

const HighGainAccountAnalysis = Loadable(
    lazy(() => import('page/admin/RiskManagement/HighGainAccountAnalysis')),
);

const LowGainAccountAnalysis = Loadable(
    lazy(() => import('page/admin/RiskManagement/LowGainAccountAnalysis')),
);

const ProfitOverCurrency = Loadable(
    lazy(() => import('page/admin/RiskManagement/ProfitOverCurrency')),
);
const ClosedInSencond = Loadable(
    lazy(() => import('page/admin/RiskManagement/ClosedInSencond')),
);
const OpenInSencond = Loadable(
    lazy(() => import('page/admin/RiskManagement/OpenInSencond')),
);
const DailyProfitOverCurrency = Loadable(
    lazy(() => import('page/admin/RiskManagement/DailyProfitOverCurrency')),
);
const ProfitableTradesClosedInSencond = Loadable(
    lazy(() => import('page/admin/RiskManagement/ProfitableTradesClosedInSencond')),
);
const DailyProfitOverPerc = Loadable(
    lazy(() => import('page/admin/RiskManagement/DailyProfitOverPerc')),
);
const AccountAssessment = Loadable(
    lazy(() => import('page/admin/RiskManagement/AccountAssessment')),
);
const RiskAccount = Loadable(
    lazy(() => import('page/admin/RiskManagement/RiskAccount')),
);
const EquityReports = Loadable(
    lazy(() => import('page/admin/RiskManagement/EquityReports')),
);
const NetReports = Loadable(
    lazy(() => import('page/admin/RiskManagement/NetReports')),
);
const ClientReports = Loadable(
    lazy(() => import('page/admin/RiskManagement/ClientReports')),
);
const ManageCommissionReport = Loadable(
    lazy(() => import('page/admin/RiskManagement/ManageCommissionReports')),
);
const ManageCommissionReportDetail = Loadable(
    lazy(() => import('page/admin/RiskManagement/ManageCommissionReportsDetail')),
);
const TaskReports = Loadable(
    lazy(() => import('page/admin/RiskManagement/TaskReports')),
);
const CommissionReports = Loadable(
    lazy(() => import('page/admin/RiskManagement/CommissionReports')),
);
const ProfitsAnalysis = Loadable(
    lazy(() => import('page/admin/RiskManagement/ProfitsAnalysis')),
);
const CommissionAnalysis = Loadable(
    lazy(() => import('page/admin/RiskManagement/CommissionAnalysis')),
);

const AdminNotification = Loadable(
    lazy(() => import('page/admin/Notification')),
);

const PassOtpResetComposePage = Loadable(
    lazy(() => import('page/PassOtpResetComposePage')),
);

const ConfidencialAdmin = Loadable(
    lazy(() => import('page/admin/ConfidencialAdmin/ConfidencialAdmin')),
);
const ConfidencialDashboard = Loadable(
    lazy(() => import('page/admin/ConfidencialAdmin/ConfidencialDashboard')),
);
const ConfidencialAdminDetail = Loadable(
    lazy(() => import('page/admin/ConfidencialAdmin/ConfidencialAdminDetail')),
);

// reports
const ClosedPnlAnalysis = Loadable(lazy(() => import('page/managementReport/closedTrade/ClosedAnalysis')));
const ClosedPnlBySymbol = Loadable(lazy(() => import('page/managementReport/closedTrade/ClosedAnalysisDetails/PnlBySymbols')));
const ClosedByCurrencies = Loadable(lazy(() => import('page/managementReport/closedTrade/ClosedAnalysisDetails/PnlByCurrencies')));
const ClosedByGroups = Loadable(lazy(() => import('page/managementReport/closedTrade/ClosedAnalysisDetails/PnlByGroups')));
const ClosedByBooks = Loadable(lazy(() => import('page/managementReport/closedTrade/ClosedAnalysisDetails/PnlByBooks')));
const ClosedByGainerOrLosers = Loadable(lazy(() => import('page/managementReport/closedTrade/ClosedAnalysisDetails/TopGainOrLose')));
const ClosedByTrades = Loadable(lazy(() => import('page/managementReport/closedTrade/ClosedAnalysisDetails/GainTrades')));
const ClosedByAccounts = Loadable(lazy(() => import('page/managementReport/closedTrade/ClosedAnalysisDetails/GainAccounts')));
const ClosedByServers = Loadable(lazy(() => import('page/managementReport/closedTrade/ClosedAnalysisDetails/ByServers')));


const VolumeAnalysis = Loadable(lazy(() => import('page/managementReport/closedTrade/VolumeAnalysis')));
const TradeAccountAnalysis = Loadable(lazy(() => import('page/managementReport/closedTrade/TradeAccountAnalysis')));
const ClosedCommissionAnalysis = Loadable(lazy(() => import('page/managementReport/closedTrade/CommissionAnalysis')));
const HoldingPositionsAnalysis = Loadable(lazy(() => import('page/managementReport/closedTrade/HoldingPositionsAnalysis')));