import React, {useState} from 'react';
import {
    Grid,
    GridItem,
    Flex,
    Text,
    Box,
    Stack,
    IconButton,
    HStack,
    Spinner,
    Button,
    Icon,
} from '@chakra-ui/react';
import {get} from 'lodash';
// components
import TreeView from 'components/TreeView';
import CardContainer from 'components/CardContainer';
import Page from 'components/Page';
import CustomTable from 'components/CustomTable';
import DateRangeSelector from 'components/DateRangeSelector';
import {ExportButton} from 'components/StyledBtn';
import ClientIbAgentClosedOrderTable from 'components/Tables/ClientIbAgentClosedOrderTable';
import EditMT4RebateDialog from 'components/Dialog/EditMT4RebateDialog';
import EditMT5RebateDialog from 'components/Dialog/EditMT5RebateDialog';
import IBClosedOrderDialog from 'components/Dialog/IBClosedOrderDialog';
// hooks
import {useTranslation} from 'react-i18next';
import {useUserIbDetail, useTreeView} from 'hooks/datahook/useIbAgent';
import {useUserForexAccountList, useCloseOrders} from 'hooks/datahook/forex';
import {useAuthStore} from 'stores/authStore';
import {useUserClosedOrderSummary} from 'hooks/datahook/useTransaction';

// icons
import {AiOutlineRollback} from 'react-icons/ai';
import {HiOutlineEye} from 'react-icons/hi';
import {BiEditAlt} from 'react-icons/bi';
import TableMenu from 'components/TableCell/TableMenu';
import {percentageFormator} from '../../../utils/formator';
import numeral from 'numeral';
import { StyledButton } from 'components/StyledBtn';

const Sublevels = () => {
    const [loginState, setLoginState] = useState(null);
    const [mt4Open, setMt4Open] = useState(false);
    const [mt5Open, setMt5Open] = useState(false);
    const [closedOrderOpen, setClosedOrderOpen] = useState(false);
    const [date, setDate] = useState({
        start: null,
        end: null,
    });
    const [subIbDetail, setSubIbDetail] = useState(null);
    const {user} = useAuthStore();

    const {
        data: accounts,
        page,
        setPage,
        totalPage,
        totalItems,
        pageSize,
        isLoading: accountLoading
    } = useUserForexAccountList({
        user: get(subIbDetail, 'userId'),
    });

    const swr = useUserClosedOrderSummary({
        accountId: loginState?.id,
        startDate: date?.start,
        endDate: date?.end,
        pageSize: 20,
    });
    const {data, isLoading} = useTreeView(user);
    const {MT4Rebate, MT5Rebate,mutate} = useUserForexAccountList({
        user: get(data, '[0].userId'),
        type: 'Rebate',
    });
    const {data: detail} = useUserIbDetail({
        nodeId: get(subIbDetail, 'id'),
    });

    const {t} = useTranslation('app');
    const menu = (rowData) => [
        {
            label: t('view'),
            value: 'view',
            onClick: (rowData) => {
                setLoginState(rowData);
            },
            icon: HiOutlineEye,
        },
    ];
    // const {data: subIbDetail} = useSubIbDetail(user)
    const column = [
        {
            label: t('accountType'),
            dataKey: 'accountType',
            columProps: {
                flexGrow: 1,
            },
            renderCell: (rowData) => {
                return `${get(rowData, 'accountSource')} ${get(
                    rowData,
                    'type',
                )}`;
            },
        },
        {
            label: t('accountNum'),
            dataKey: 'login',
            columProps: {
                flexGrow: 1,
            },
        },
        {
            label: t('currency'),
            dataKey: 'currency',
            columProps: {
                flexGrow: 1,
            },
        },
        {
            label: t('status'),
            dataKey: 'status',
            columProps: {
                flexGrow: 1,
            },
            // renderCell: (rowData) => {
            //     return get(rowData, 'clientDetail.saleDetail.saleCode') &&
            //         get(rowData, 'clientDetail.ibCode')
            //         ? `${get(
            //               rowData,
            //               'clientDetail.saleDetail.saleCode',
            //           )}.${get(rowData, 'clientDetail.ibCode')}`
            //         : get(rowData, 'clientDetail.saleDetail.saleCode') &&
            //           !get(rowData, 'clientDetail.ibCode')
            //         ? get(rowData, 'clientDetail.saleDetail.saleCode')
            //         : !get(rowData, 'clientDetail.saleDetail.saleCode') &&
            //           get(rowData, 'clientDetail.ibCode')
            //         ? get(rowData, 'clientDetail.ibCode')
            //         : '';
            // },
        },
        {
            label: t('closedOrders'),
            dataKey: 'closeOrders',
            columProps: {
                flexGrow: 1,
            },
            renderCell: (rowData) => {
                if (get(rowData, 'type') === 'Rebate') {
                    return '';
                }
                return numeral(get(rowData, 'rawData.volume')).format('0,0.00') ;
            },
        },
        {
            label: t(''),
            dataKey: 'action',
            columProps: {
                align: 'center',
                flexGrow: 1,
            },
            renderCell: (rowData) => {
                return (
                    <StyledButton
                        onClick={() => onRowClick(rowData)}
                        variant="primary"
                        fontWeight={400}
                        fontSize={10}
                        size="xs"
                        h={'20px'}
                    >
                        {t('viewMore')}
                    </StyledButton>
                );
            },
        },
    ];

    const onRowClick = (value) => {
        setLoginState(value);
        setClosedOrderOpen(true);
    };
    return (
        <Page
            title={t('sublevels')}
            style={{height: 'calc(100vh - 135px)', overflow: 'hidden'}}
        >
            <HStack spacing={4} h="100%" alignItems={'flex-start'}>
                <Stack
                    width={350}
                    display="flex"
                    flexDirection={'column'}
                    h="100%"
                >
                    <CardContainer h="100%">
                        {isLoading ? (
                            <Box
                                display={'flex'}
                                justifyContent="center"
                                alignItems={'center'}
                                h="100%"
                            >
                                <Spinner />
                            </Box>
                        ) : data?.length ? (
                            <Box overflow={'auto'}>
                                <TreeView
                                    data={data}
                                    setSubIbDetail={setSubIbDetail}
                                    isClient={true}
                                />
                            </Box>
                        ) : (
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent="center"
                            >
                                {t('noData')}
                            </Box>
                        )}
                    </CardContainer>
                </Stack>
                <Stack flex={1} h="100%">
                    <CardContainer h="100%">
                        {data?.[0]?.['children'] ? (
                            <>
                                <Box>
                                    <Text mb={2} fontWeight={500}>
                                        {get(subIbDetail, 'childrenName')}
                                    </Text>

                                    <Stack>
                                        <Stack
                                            direction={{
                                                base: 'column',
                                                lg: 'row',
                                            }}
                                        >
                                            <Flex fontSize={'sm'} flex={1}>
                                                <Text mr={1}>
                                                    {get(detail, 'type') ===
                                                    'ib'
                                                        ? t('ibRef')
                                                        : t('clientRef')}
                                                    :
                                                </Text>
                                                <Text>
                                                    {get(detail, 'ref')}
                                                </Text>
                                            </Flex>
                                            {get(detail, 'type') === 'ib' ? (
                                                <Flex fontSize={'sm'} flex={1}>
                                                    <Text mr={1}>
                                                        {t('ibCode')}:
                                                    </Text>
                                                    <Text>
                                                        {get(detail, 'ibCode')}
                                                    </Text>
                                                </Flex>
                                            ) : (
                                                ''
                                            )}
                                        </Stack>
                                        <Stack direction={{
                                                base: 'column',
                                                lg: 'row',
                                            }}>
                                            {get(detail, 'type') ===
                                            'client' ? (
                                                ''
                                            ) : (
                                                <Flex fontSize={'sm'} flex={1}>
                                                    <Text mr={1}>
                                                        {t('ibRate')}:
                                                    </Text>
                                                    <Text>
                                                        {get(
                                                            detail,
                                                            'commissionRate',
                                                        )
                                                            ? percentageFormator(
                                                                  get(
                                                                      detail,
                                                                      'commissionRate',
                                                                  ),
                                                              )
                                                            : ''}
                                                    </Text>
                                                </Flex>
                                            )}
                                            <Flex fontSize={'sm'} flex={1}>
                                                    <Text mr={1}>
                                                        {t('sale')}:
                                                    </Text>
                                                    <Text>
                                                        {get(detail, 'saleName')}
                                                    </Text>
                                                </Flex>
                                        </Stack>
                                        <Stack
                                            direction={{
                                                base: 'column',
                                                lg: 'row',
                                            }}
                                        >
                                            {get(detail, 'ref') ===
                                            get(data, '[0].ref') ? (
                                                ''
                                            ) : (
                                                <>
                                                    <Flex
                                                        fontSize={'sm'}
                                                        flex={1}
                                                    >
                                                        <Text mr={1}>
                                                            {t(
                                                                'mt4RebateAccount',
                                                            )}
                                                            :
                                                        </Text>
                                                        <Text
                                                            display={'flex'}
                                                            alignItems="center"
                                                        >
                                                            {MT4Rebate
                                                                ? get(
                                                                      MT4Rebate,
                                                                      'login',
                                                                  )
                                                                : ''}
                                                            <Box ml={2}>
                                                                <BiEditAlt
                                                                    onClick={() =>
                                                                        setMt4Open(
                                                                            true,
                                                                        )
                                                                    }
                                                                />
                                                            </Box>
                                                        </Text>
                                                    </Flex>
                                                    <Flex
                                                        fontSize={'sm'}
                                                        flex={1}
                                                    >
                                                        <Text mr={1}>
                                                            {t(
                                                                'mt5RebateAccount',
                                                            )}
                                                            :
                                                        </Text>
                                                        <Text
                                                            display={'flex'}
                                                            alignItems="center"
                                                        >
                                                            {MT5Rebate
                                                                ? get(
                                                                      MT5Rebate,
                                                                      'login',
                                                                  )
                                                                : ''}
                                                            <Box ml={2}>
                                                                <BiEditAlt
                                                                    onClick={() =>
                                                                        setMt5Open(
                                                                            true,
                                                                        )
                                                                    }
                                                                />
                                                            </Box>
                                                        </Text>
                                                    </Flex>
                                                </>
                                            )}
                                        </Stack>
                                    </Stack>
                                </Box>
                                <Text fontSize={'lg'} pt={4} fontWeight={500}>
                                    {t('forexAccounts')}
                                </Text>
                                <Stack overflow={'auto'}>
                                    <CustomTable
                                        data={accounts}
                                        page={page}
                                        setPage={setPage}
                                        totalPage={totalPage}
                                        totalItems={totalItems}
                                        column={column}
                                        pageSize={pageSize}
                                        // onRowClick={onRowClick}
                                        title={t('fxTradeAccounts')}
                                        isLoading={accountLoading}
                                        noCard
                                    />
                                </Stack>
                            </>
                        ) : (
                            <Box
                                display={'flex'}
                                alignItems="center"
                                justifyContent={'center'}
                                h="100%"
                            >
                                {t('noData')}
                            </Box>
                        )}
                    </CardContainer>
                </Stack>
            </HStack>
            <EditMT4RebateDialog
                mutate={mutate}
                value={get(MT4Rebate, 'id',)}
                nodeId={get(subIbDetail, 'id')}
                user={get(data, '[0].userId')}
                open={mt4Open}
                onClose={() => setMt4Open(false)}
            />
            <EditMT5RebateDialog
                mutate={mutate}
                value={get(MT5Rebate, 'id',)}
                nodeId={get(subIbDetail, 'id')}
                user={get(data, '[0].userId')}
                open={mt5Open}
                onClose={() => setMt5Open(false)}
            />
            <IBClosedOrderDialog
                open={closedOrderOpen}
                onClose={() => setClosedOrderOpen(false)}
                dailySwr={swr}
                loginState={loginState}
                setLoginState={setLoginState}
                date={date}
                setDate={setDate}
            />
        </Page>
    );
};

export default Sublevels;
