import {Box, Stack} from '@chakra-ui/react';
import CustomTab from 'components/CustomTab';
import {find, get, last, split, trim} from 'lodash';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useMatches, useNavigate} from 'react-router-dom';
import {paths} from 'routes/path';
import {useUserForexAccountList, useCloseOrders} from 'hooks/datahook/forex';
import {useAuthStore} from 'stores/authStore';

const IBLayout = () => {
    const {t} = useTranslation('app');
    const {user} = useAuthStore();
    const matches = useMatches();
    const matchPath = get(last(matches), 'pathname');
    const navigate = useNavigate();
    const {data} = useUserForexAccountList({user: user.id, onlyRewired: true});

    const tabs = [
        {
            label: t('overview'),
            value: 'overview',
            path: paths.ibOverview,
        },
        {
            label: t('sublevels'),
            value: 'sublevels',
            path: paths.ibSublevels,
        },
        ...(data.length
            ? [
                  {
                      label: t('wiredAccounts'),
                      value: 'wiredAccounts',
                      path: paths.wiredAccounts,
                  },
              ]
            : []),
        {
            label: t('ibReports'),
            value: 'ibReports',
            path: paths.ibReports,
        },
        {
            label: t('pendingInvitations'),
            value: 'pendingInvitations',
            path: paths.pendingInvitations,
        },
    ];
    const value =
        find(tabs, (o) => o.path === matchPath)?.value || tabs[0]?.value;

    return (
        <Stack spacing={4} width="100%">
            <CustomTab
                tabs={tabs}
                tabOnly
                value={value}
                onChange={(v) => {
                    navigate(find(tabs, (o) => o.value === v)?.path);
                }}
            />
            <Box width={'100%'}>
                <Outlet />
            </Box>
        </Stack>
    );
};

export default IBLayout;
