import CustomDialog from './CustomDialog';
import {
    Flex,
    Text,
    IconButton,
    HStack,
    Stack,
    Modal,
    ModalContent,
    ModalOverlay,
    ModalBody,
    Button,
} from '@chakra-ui/react';
import {get} from 'lodash';
import {useState} from 'react';

import {AiOutlineRollback} from 'react-icons/ai';
import DateRangeSelector from 'components/DateRangeSelector';
import {ExportButton, StyledButton} from 'components/StyledBtn';
import ClientIbAgentClosedOrderTable from 'components/Tables/ClientIbAgentClosedOrderTable';
import {useTranslation} from 'react-i18next';
import {CloseButton} from '@chakra-ui/react';
import {usePreference} from 'hooks/usePreference';
import {currencyFormatorOfficial, formatDayMonthYear} from 'utils/formator';
import numeral from 'numeral';
import Icon from 'components/Icon/IconByCodeStr';
import {HiDownload} from 'react-icons/hi';
import {useCloseOrders} from 'hooks/datahook/forex';
import ClosedOrdersTable from 'components/Tables/ClosedOrdersTable';

export default ({
    open,
    onClose,
    dailySwr,
    loginState,
    date,
    setDate = () => {},
    setLoginState = () => {},
}) => {
    const {t} = useTranslation('app');
    const [isDetail, setIsDetail] = useState(false);
    const [detail, setDetail] = useState(null);
    const {getStockColorValue} = usePreference();

    const swr = useCloseOrders({
        accountId: detail?.accountId,
        startDate: get(detail, 'date'),
        endDate: get(detail, 'date'),
        userId: get(loginState, 'clientDetail.id'),
    });
    const onDownload = () => {};
    console.log(loginState);
    return (
        <Modal
            isOpen={open}
            onClose={onClose}
            isCentered
            scrollBehavior="outside"
            size={'auto'}
            spacing={3}
        >
            <ModalOverlay />
            <ModalContent maxW={'950px'}>
                {!isDetail ? (
                    <Stack px={6} pt={4}>
                        <Flex
                            justifyContent={'space-between'}
                            alignItems="center"
                        >
                            <Flex fontWeight={500}>
                                <Text color="success.100" mx={1}>
                                    {get(loginState, 'login')}
                                </Text>
                                <Text>{t('closedOrders')}</Text>
                            </Flex>
                            <CloseButton
                                alignSelf="flex-start"
                                position="relative"
                                right={-1}
                                top={-1}
                                onClick={onClose}
                            />
                        </Flex>
                        <Stack pt={2} direction={{base: 'column', md: 'row'}}>
                            <HStack flex={1}>
                                <DateRangeSelector
                                    defaultValue={date}
                                    onChangeValue={setDate}
                                />
                            </HStack>
                            <ExportButton>{t('export')}</ExportButton>
                        </Stack>
                        <ModalBody
                            maxHeight={{base: '100%', md: '70vh'}}
                            overflow="auto"
                            px={0}
                        >
                            <ClientIbAgentClosedOrderTable
                                userId={get(loginState, 'rawData.user')}
                                {...dailySwr}
                                onClose={onClose}
                                setIsDetail={setIsDetail}
                                detail={detail}
                                setDetail={setDetail}
                            />
                        </ModalBody>
                    </Stack>
                ) : (
                    <>
                        <HStack w={'100%'} px={6} pt={4}>
                            <Stack w={'100%'} spacing={1}>
                                <Text fontWeight={'600'} fontSize={'xl'}>
                                    {t('closedOrdersDetail')}
                                </Text>
                                <Stack
                                    spacing={4}
                                    direction={{base: 'column', md: 'row'}}
                                    alignItems={{base: 'stretch', md: 'center'}}
                                    fontSize={'xs'}
                                >
                                    <HStack spacing={4}>
                                        <Text>
                                            {t('account')}:{' '}
                                            {get(detail, 'origin')}{' '}
                                            {get(detail, 'login')}
                                        </Text>
                                        <Text>
                                            {t('date')}:{' '}
                                            {formatDayMonthYear(
                                                get(detail, 'date'),
                                            )}
                                        </Text>
                                    </HStack>
                                    <HStack spacing={4}>
                                        <Text>
                                            {t('totalClosedLots')}:{' '}
                                            {numeral(
                                                get(detail, 'volume'),
                                            ).format('0,0.00')}
                                        </Text>
                                        <HStack spacing={1}>
                                            <Text>{t('profits')}:</Text>
                                            <Text
                                                color={getStockColorValue(
                                                    get(detail, 'profits'),
                                                )}
                                            >
                                                {currencyFormatorOfficial(
                                                    get(detail, 'profits'),
                                                    get(detail, 'currency'),
                                                    true,
                                                )}
                                            </Text>
                                        </HStack>
                                    </HStack>
                                </Stack>
                            </Stack>
                            <HStack justifyContent={'flex-end'} mb={2}>
                                <Button
                                    variant={'primary'}
                                    size="sm"
                                    leftIcon={<Icon as={HiDownload} />}
                                    onClick={onDownload}
                                >
                                    {t('export')}
                                </Button>
                                <StyledButton onClick={() => setIsDetail(false)}>
                                    {/* <AiOutlineRollback /> */}
                                    {t('return')}
                                </StyledButton>
                            </HStack>
                            {/* <IconButton
                                onClick={() => setIsDetail(false)}
                                position="relative"
                                right={-1}
                                top={-1}
                            >
                            </IconButton> */}
                        </HStack>
                        <ModalBody
                            maxHeight={{base: '100%', md: '70vh'}}
                            overflow="auto"
                            pb={6}
                        >
                            <ClosedOrdersTable {...swr} />
                        </ModalBody>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};
