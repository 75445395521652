import {
    useSWRPaginationTemplate,
    useSWRTemplate,
} from 'hooks/dataHooksTemplate';
import {useAuth} from 'hooks/useAuth';
import {get, sortBy, reverse, orderBy, flatten, keys} from 'lodash';
import {cleanObjectNilValue} from 'utils/clean';
import {api} from './common';
import {processClosedOrders, processForexAccountList} from 'data/forex';
import {formatedStartEndDate, processStartEndDateLocale} from 'utils/time';
import {
    formatMonthDataAndYear,
    formatDateInTimezone,
    getLocalTime,
} from 'utils/formator';
import {
    gatewayDisabled,
    paymentWaysIdChannelMap,
    paymentWaysNameChannelMap,
} from 'data/paymentGateway';
import client from 'helper/httpClient';
import { usePreference } from 'hooks/usePreference';
import { useUserPreference } from './settings';

export const useCommonsAccountList = (options = {}) => {
    const {
        type = null,
        user,
        enableOnly,
        login = null,
        request = true,
    } = options;

    const swr = useSWRTemplate({
        key: request
                ? [
                    'commonsAccountList',
                    type,
                    user,
                    login,
                    enableOnly,
                ] : null,
        request: async ([
                            _,
                            _type,
                            _user,
                            _login,
                            _enableOnly,
                        ]) => {
            const {result} = await api().get(
                ['commons', 'account-list'],
                cleanObjectNilValue({
                    type: _type != 'Hidden' ? _type : null,
                    user: _user,
                    login: _login,
                }),
            );

            return (result || []).map((item)=>(
                {
                    ...item,
                    label: `${get(item, 'login')} ${get(item, 'source')} ${get(
                        item,
                        'currency',
                    )}`,
                    value:  `${get(item, 'login')},${get(item, 'id')},${get(item, ':user.name')},${get(item, ':user.id')}`,
                }
            ))
                .filter((item) =>
                    _enableOnly ? item.enable : true,
                );
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useForexAccountList = (options = {}) => {
    const {
        type = null,
        user,
        enableOnly,
        disableOnly,
        disReadonly,
        request = true,
    } = options;
    const {isUser, id} = useAuth();
    const swr = useSWRTemplate({
        key: request
            ? [
                  isUser
                      ? `userForexAccountList/${id}`
                      : 'adminForexAccountList',
                  type,
                  user,
                  enableOnly,
                  disableOnly,
                  disReadonly,
                  isUser,
              ]
            : null,
        request: async ([
            _,
            _type,
            _user,
            _enableOnly,
            _disableOnly,
            _disReadonly,
            _isUser,
        ]) => {
            const {result} = await api().get(
                ['fx', 'account'],
                cleanObjectNilValue({
                    type: _type != 'Hidden' ? _type : null,
                    isHidden: _type == 'Hidden' ? true : _isUser ? false : null,
                    user: _user,
                }),
            );

            return orderBy(
                result,
                (o) =>
                    Number(get(o, 'balance') || 0) -
                    Number(get(o, 'locked') || 0),
                'desc',
            )
                .map(processForexAccountList)
                .filter((item) =>
                    _enableOnly
                        ? item.enable && item.accountType != 'Disabled'
                        : true,
                )
                .filter((item) =>
                    _disableOnly ? !item.enable && item.type !== 'Demo' : true,
                )
                .filter((item) => (_disReadonly ? !item.enableReadonly : true))
                .filter(
                    (item) =>
                        item?.accountType != 'PAMM' &&
                        item?.accountType != 'MAM',
                );
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useIBForexAccountList = (options = {}) => {
    const {type = null, user, enableOnly, disableOnly, disReadonly} = options;
    const {isUser, id} = useAuth();
    const swr = useSWRTemplate({
        key: user
            ? [
                  isUser
                      ? `userIBForexAccountList/${id}`
                      : 'adminIBForexAccountList',
                  type,
                  user,
                  enableOnly,
                  disableOnly,
                  disReadonly,
                  isUser,
              ]
            : null,
        request: async ([
            _,
            _type,
            _user,
            _enableOnly,
            _disableOnly,
            _disReadonly,
            _isUser,
        ]) => {
            const {result} = await api().get(
                ['fx', 'account'],
                cleanObjectNilValue({
                    type: _type != 'Hidden' ? _type : null,
                    isHidden: _type == 'Hidden' ? true : _isUser ? false : null,
                    user: _user,
                }),
            );

            return orderBy(
                result,
                (o) =>
                    Number(get(o, 'balance') || 0) -
                    Number(get(o, 'locked') || 0),
                'desc',
            )
                .map(processForexAccountList)
                .filter((item) =>
                    _enableOnly
                        ? item.enable && item.accountType != 'Disabled'
                        : true,
                )
                .filter((item) =>
                    _disableOnly ? !item.enable && item.type !== 'Demo' : true,
                )
                .filter((item) => (_disReadonly ? !item.enableReadonly : true))
                .filter(
                    (item) =>
                        item?.accountType != 'PAMM' &&
                        item?.accountType != 'MAM',
                );
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useUserForexAccountList = (options = {}) => {
    const {
        type = null,
        user,
        enableOnly,
        disableOnly,
        disReadonly,
        source,
        onlyRewired
    } = options;
    const {isUser, id} = useAuth();
    const swr = useSWRTemplate({
        key: user
            ? [
                  isUser
                      ? `userForexAccountList/${id}`
                      : 'adminForexAccountList',
                  type,
                  user,
                  enableOnly,
                  disableOnly,
                  disReadonly,
                  isUser,
                  source,
                  onlyRewired
              ]
            : null,
        request: async ([
            _,
            _type,
            _user,
            _enableOnly,
            _disableOnly,
            _disReadonly,
            _isUser,
            _source,
            _onlyRewired
        ]) => {
            const {result} = await api().get(
                ['ib', 'account', _user],
                cleanObjectNilValue({type: _type, source: _source, onlyRewired: _onlyRewired}),
            );

            return result
                .map(processForexAccountList)
                .filter((item) =>
                    _enableOnly
                        ? item.enable && item.accountType != 'Disabled'
                        : true,
                )
                .filter((item) =>
                    _disableOnly ? !item.enable && item.type !== 'Demo' : true,
                )
                .filter((item) => (_disReadonly ? !item.enableReadonly : true))
                .filter(
                    (item) =>
                        item?.accountType != 'PAMM' &&
                        item?.accountType != 'MAM',
                );
        },
        defaultValue: [],
    });
    return {
        ...swr,
        MT4Rebate:
            swr.data.find(
                (item) => item.accountSource === 'MT4' && item.isDefault,
            ) ||
            swr.data.find(
                (item) =>
                    item.accountSource === 'MT4' && item?.type === 'Rebate',
            ),
        MT5Rebate:
            swr.data.find(
                (item) => item.accountSource === 'MT5' && item.isDefault,
            ) ||
            swr.data.find(
                (item) =>
                    item.accountSource === 'MT5' && item?.type === 'Rebate',
            ),
    };
};

export const useAccountForexAccountList = (options = {}) => {
    const {id} = useAuth();
    const swr = useSWRTemplate({
        key: id ? ['useAccountForexAccountList', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(['fx', 'account-group']);

            const odj = {};
            for (const key in result) {
                if (Object.prototype.hasOwnProperty.call(result, key)) {
                    const element = result[key];
                    odj[key] = orderBy(
                        element,
                        (o) =>
                            Number(get(o, 'balance') || 0) -
                            Number(get(o, 'locked') || 0),
                        'desc',
                    ).map(processForexAccountList);
                }
            }
            return odj;
        },
        defaultValue: {
            Live: [],
            Rebate: [],
            Demo: [],
            Hidden: [],
            Disabled: [],
        },
    });
    return {
        ...swr,
    };
};

export const useForexAccountDetail = (options = {}) => {
    const {id, request = true} = options;
    const swr = useSWRTemplate({
        key: id && request ? ['forexAccountDetail', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(['fx', 'account', _id]);
            return processForexAccountList(result);
        },
        defaultValue: {},
    });
    return {
        ...swr,
    };
};

export const editMtRebateAccount = async (data) => {
    const {result} = await api().post(
        ['ib', 'rebate-account'],
        cleanObjectNilValue(data),
    );
    return result;
};

export const useForexTransaction = (options = {}) => {
    const {startDate, endDate, accountId, status, type, ticket, user} = options;
    const formattedDateRange = formatedStartEndDate({
        startDate,
        endDate,
    });

    const swr = useSWRPaginationTemplate({
        key: [
            'forexTransaction',
            formattedDateRange.startDate,
            formattedDateRange.endDate,
            accountId,
            status,
            type,
            ticket,
            user,
        ],
        request: async (_key, _pageIndex, _pageSize) => {
            const [
                _,
                _startDate,
                _endDate,
                _accountId,
                _status,
                _type,
                _ticket,
                _user,
            ] = _key;
            const {result} = await api().get(
                ['transaction'],
                cleanObjectNilValue({
                    pageIndex: _pageIndex,
                    pageSize: _pageSize,
                    startDate: _startDate,
                    endDate: _endDate,
                    accountId: _accountId,
                    status: _status,
                    type: _type,
                    ticket: _ticket,
                    user: _user,
                }),
            );

            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items'),
                },
            };
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useFXDashboard = (options = {}) => {
    const {user = null} = options;
    const {isUser, id} = useAuth();
    const swr = useSWRTemplate({
        key: id ? ['FXDashboard', id, user] : null,
        request: async ([_, _id, _user]) => {
            const {result} = await api().get(
                [isUser ? 'fx' : 'summary', 'dashboard'],
                cleanObjectNilValue({
                    user: _user,
                }),
            );
            return result;
        },
        defaultValue: {},
    });
    return {
        ...swr,
    };
};
export const useUserUpperIb = (options = {}) => {
    const {user = null} = options;
    const swr = useSWRTemplate({
        key: user ? ['user/upper-ib', user] : null,
        request: async ([_, _user]) => {
            const {result} = await api().get(
                ['user', 'upper-ib'],
                cleanObjectNilValue({
                    user: _user,
                }),
            );
            return result;
        },
        defaultValue: {},
    });
    return {
        ...swr,
    };
};

export const useFundingDashboard = (options = {}) => {
    const {isUser, id} = useAuth();
    const swr = useSWRTemplate({
        key: isUser && id ? ['fundingDashboard', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(['funding', 'dashboard']);
            return result;
        },
        defaultValue: {},
    });
    return {
        ...swr,
    };
};

export const useLeverages = (options = {}) => {
    const {isUser} = useAuth();
    const swr = useSWRTemplate({
        key: isUser ? ['leverages'] : null,
        request: async (_) => {
            const {result} = await api().get(['fx', 'leverage-setting']);
            return (result || []).map((item) => {
                return {
                    label: `1:${item}`,
                    value: item,
                };
            });
        },
        defaultValue: [],
    });

    return {
        ...swr,
    };
};

const depositWayOrder = [
    'worldpay',
    'worldpay3ds',
    'eft',
    'nacepay',
    'nacepayWidget',
    'finmo',
    'web3',
    'awepay',
    'fasapay',
    'dragonpay',
    'usdt',
    'bitwallet',
    'exLink',
    'koreanSolution',
    'customerSupport',
];
export const useDepositWay = (options = {}) => {
    const {data: preference} = useUserPreference();
    const swr = useSWRTemplate({
        key: ['depositWay'],
        request: async ([_]) => {
            const {result} = await api().get(['deposit', 'payment-gateway']);
            return flatten(
                (result || [])
                    .map((item) => ({
                        ...item,
                        img:
                            item?.displayName === 'Credit/Debit Card'
                                ? 'VisaMaster'
                                : item?.displayName === 'Bank Transfer'
                                ? 'BankTransfer'
                                : item?.displayName === 'Web3.0'
                                ? 'USDT'
                                : item?.displayName === 'Customer support'
                                ? 'CustomerSupport'
                                : item?.displayName,
                        channel: paymentWaysNameChannelMap[item.displayName],
                        displayName: item.displayName==='Finmo'? 'PayNow': item.displayName,
                    }))
                    .map((item) => {
                        if (item.channel === 'nacepay') {
                            return [
                                item,
                                {
                                    ...item,
                                    channel: 'nacepayWidget',
                                    displayName: 'nacepayWidget',
                                },
                            ];
                        } else if (item.channel ==='worldpay' && preference?.enableWorldpay3DS) {
                            return [
                                {
                                    ...item,
                                    channel: 'worldpay3ds',
                                    displayName: 'worldpay3ds',
                                },
                            ];
                           
                        }
                        else {
                            return item;
                        }
                    }),
            )
                .filter((item) => !gatewayDisabled.includes(item.channel))
                .sort(
                    (a, b) =>
                        depositWayOrder.indexOf(a.channel) -
                        depositWayOrder.indexOf(b.channel),
                );
        },

        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useAccountGroup = (options = {}) => {
    const swr = useSWRTemplate({
        key: ['account-group'],
        request: async ([_]) => {
            const {result} = await api().get(['commons', 'account-group']);
            return result;
        },
        defaultValue: {},
    });
    return {
        ...swr,
    };
};

export const useCloseOrders = (options = {}) => {
    const {
        status,
        startDate,
        endDate,
        accountId,
        userId,
        pageSize = 100,
        request = true,
    } = options;
    console.log(userId);
    const swr = useSWRPaginationTemplate({
        key:
            accountId && request
                ? ['closeOrders', status, startDate, endDate, accountId, userId]
                : null,
        pageSize: pageSize,
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _status, _startDate, _endDate, _accountId, _userId] =
                _key;

            const query = {
                startDate: _startDate
                    ? formatDateInTimezone(_startDate, 'Factory', 'yyyy-MM-dd')
                    : null,
                endDate: _endDate
                    ? formatDateInTimezone(_endDate, 'Factory', 'yyyy-MM-dd')
                    : null,
                accountId: _accountId,
                userId: Number(_userId) || null,
                pageSize: _pageSize,
                pageIndex: _pageIndex - 1,
            };
            const {result} = await api().get(
                ['fx', 'close-order'],
                cleanObjectNilValue(query),
            );
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map(processClosedOrders),
                },
            };
        },
        defaultValue: {
            totalItems: 0,
            items: [],
        },
    });
    return swr;
};

export const useFXClosedTrends = (options = {}) => {
    const {type, login} = options;
    const swr = useSWRTemplate({
        key: type ? ['FXClosedTrends', type, login] : null,
        request: async ([_, _type, _login]) => {
            const {result} = await api().get(
                ['fx', 'trends'],
                cleanObjectNilValue({
                    type: _type,
                    login: _login,
                    cycle: 'month',
                }),
            );
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useFXBalanceTrends = (options = {}) => {
    const {yearMonth, accountId, user = null} = options;
    const swr = useSWRTemplate({
        key: ['FXBalanceTrends', yearMonth, accountId, user],
        request: async ([_, _yearMonth, _accountId, _user]) => {
            const query = {
                yearMonth: _yearMonth,
                accountId: _accountId,
                user: _user,
            };
            const {result} = await api().get(
                ['summary', 'balance-chart'],
                cleanObjectNilValue(query),
            );
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
        monthList: swr.data?.months?.map((item) => {
            return {
                label: item,
                value: item,
            };
        }),
    };
};
export const useProfitsTrends = (options = {}) => {
    const {yearMonth, accountId, user} = options;
    const swr = useSWRTemplate({
        key: ['FXProfitsTrends', yearMonth, accountId, user],
        request: async ([_, _yearMonth, _accountId, _user]) => {
            const query = {
                yearMonth: _yearMonth,
                accountId: _accountId,
                user: _user,
            };
            const {result} = await api().get(
                ['summary', 'profits-chart'],
                cleanObjectNilValue(query),
            );
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
        data: swr.data,
        monthList: swr.data?.months?.map((item) => {
            return {
                label: item,
                value: item,
            };
        }),
    };
};

export const useClosedLotsTrends = (options = {}) => {
    const {yearMonth, accountId, user = null} = options;
    const swr = useSWRTemplate({
        key: ['closedLotsTrends', yearMonth, accountId, user],
        request: async ([_, _yearMonth, _accountId, _user]) => {
            const query = {
                yearMonth: _yearMonth,
                accountId: _accountId,
                user: _user,
            };
            const {result} = await api().get(
                ['summary', 'closed-order-chart'],
                cleanObjectNilValue(query),
            );
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
        data: swr.data,
        monthList: swr.data?.months?.map((item) => {
            return {
                label: item,
                value: item,
            };
        }),
    };
};

export const useTradesTrends = (options = {}) => {
    const {yearMonth, type, user = null, request = true} = options;
    const swr = useSWRTemplate({
        key: request ? ['tradesTrends', yearMonth, type, user] : null,
        request: async ([_, _yearMonth, _type, _user]) => {
            const query = {
                yearMonth: _yearMonth,
                user: _user,
            };
            const {result} = await api().get(
                ['summary', `${_type}-chart`],
                cleanObjectNilValue(query),
            );
            return {
                ...result,
                data: get(result, 'data').map((item) => {
                    return {
                        ...item,
                        amount:
                            type === 'deposit'
                                ? get(item, 'deposit')
                                : type === 'withdrawal'
                                ? get(item, 'withdrawal')
                                : get(item, 'commission'),
                    };
                }),
            };
        },
        defaultValue: [],
    });
    return {
        ...swr,
        data: reverse(swr.data),
        monthList: swr.data?.months?.map((item) => {
            return {
                label: item,
                value: item,
            };
        }),
    };
};

export const useCurrencyRates = (options = {}) => {
    const {isUser} = useAuth();
    const swr = useSWRTemplate({
        key: isUser ? ['currency-rates'] : null,
        request: async ([_]) => {
            const {result} = await api().get(['commons', 'currency-rates']);
            return result;
        },
        defaultValue: {},
    });
    return {
        ...swr,
    };
};

export const useFXGroups = (options = {}) => {
    const {id, source, request = true} = options;
    const {isUser} = useAuth();
    const swr = useSWRTemplate({
        key:
            !isUser && id && source && request
                ? ['fx-groups', id, source]
                : null,
        request: async ([_, _id, _source]) => {
            const {result} = await api().get([
                (_source == 'MT4'||_source == 'MT4s2') ? 'mt4' : 'mt5',
                'groups',
                _id,
            ]);
            return result;
        },
        defaultValue: [],
    });

    return {
        ...swr,
    };
};

export const changeForexPassword_ = async (data) => {
    const res = await api().post(['fx', 'password'], data);
    return res;
};

export const changeLeverage_ = async (data) => {
    const res = await api().post(['fx', 'leverage'], data);
    return res;
};

export const changeGroup_ = async (id, data) => {
    const res = await api().put(['fx', 'account', id], data);
    return res;
};

export const hiddenForexAccount_ = async (data) => {
    const res = await api().post(['fx', 'hidden'], data);
    return res;
};

export const suspendForexAccount_ = async (id, data) => {
    const res = await api().put(['fx', 'account', id], data);
    return res;
};

export const addApplyAccount_ = async (data) => {
    const res = await api().post(['fx', 'account'], cleanObjectNilValue(data));
    return res;
};

export const changeClientLeverage_ = async (id, data) => {
    const res = await api().post(['fx', 'leverage', id], data);
    return res;
};

export const useSynchronizeAccounts = () => {
    const swr = useSWRTemplate({
        key: ['fx/synchronize-accounts'],
        request: async () => {
            const {result} = await api().get(['fx', 'sync-account']);
            return result;
        },
        defaultValue: [],
    });
    return swr;
};
export const synchronizeAccounts = async (logins = []) => {
    const {result} = await api().post(['fx', 'sync-account'], {
        logins,
    });
    return result;
};

export const fxDemoTopUp_ = async (data) => {
    const res = await api().post(['fx', 'demo-deposit'], data);
    return res;
};

export const useSelectAccountList = (options = {}) => {
    const {user} = options;
    const {isUser, id} = useAuth();
    const swr = useSWRTemplate({
        key: user ? ['adminForexAccountList', user] : null,
        request: async ([
            _,
            _user,
            _enableOnly = true,
            _disableOnly,
            _disReadonly,
            _isUser,
        ]) => {
            const {result} = await api().get(
                ['commons', 'account-list'],
                cleanObjectNilValue({
                    type: 'Live',
                    userId: _user,
                }),
            );

            return orderBy(
                result,
                (o) =>
                    Number(get(o, 'balance') || 0) -
                    Number(get(o, 'locked') || 0),
                'desc',
            ).map((item) => {
                return {
                    label: `${get(item, 'source')}-${get(item, 'login')}`,
                    value: get(item, 'id'),
                    source: get(item, 'source'),
                };
            });
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useSelectLeverages = (options = {}) => {
    const {user} = options;
    const swr = useSWRTemplate({
        key: user ? ['leverages', user] : null,
        request: async ([_, _user]) => {
            const {result} = await api().get(
                ['commons', 'leverage-setting'],
                cleanObjectNilValue({userId: _user}),
            );
            return (result || []).map((item) => {
                return {
                    label: `1:${item}`,
                    value: Number(item),
                };
            });
        },
        defaultValue: [],
    });

    return {
        ...swr,
    };
};

export const useClientForexAccountList = (options = {}) => {
    const {user} = options;
    const swr = useSWRTemplate({
        key: user ? ['useClientForexAccountList', user] : null,
        request: async ([_, _user]) => {
            const {result} = await api().get(['fx', 'account-group'], {
                user: _user,
            });
            const odj = {};
            for (const key in result) {
                if (Object.prototype.hasOwnProperty.call(result, key)) {
                    const element = result[key];
                    odj[key] = orderBy(
                        element,
                        (o) =>
                            Number(get(o, 'balance') || 0) -
                            Number(get(o, 'locked') || 0),
                        'desc',
                    ).map(processForexAccountList);
                }
            }
            return odj;
        },
        defaultValue: {
            Live: [],
            Rebate: [],
            Demo: [],
            Hidden: [],
            Disabled: [],
        },
    });
    return {
        ...swr,
    };
};

export const useDefaultGroupSettings = (options = {}) => {
    const {type} = options;
    const swr = useSWRTemplate({
        key: type ? ['forex/defaultGroupSettings', type] : null,
        request: async ([_, _type]) => {
            const {result} = await api().get(
                ['forex', 'default-group-setting'],
                {type: _type},
            );
            const res = flatten(
                JSON.parse(get(result, 'value') || '[]').map((item) => {
                    return keys(item).map((key) => ({
                        currency: key,
                        ...item[key],
                    }));
                }),
            );
            return res;
        },
        defaultValue: [],
    });
    return swr;
};

export const useIBDefaultGroupSettings = (options = {}) => {
    const {type, request = true} = options;
    const swr = useSWRTemplate({
        key: type && request ? ['useIBDefaultGroupSettings', type] : null,
        request: async ([_, _type]) => {
            const {result} = await api().get(
                ['forex', 'default-group-setting'],
                {type: _type},
            );
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
        mt4GroupList: flatten(
            JSON.parse(get(swr, 'data')?.[0]?.['value'] || '[]').map((item) => {
                return keys(item).map((key) => ({
                    currency: key,
                    ...item[key],
                }));
            }),
        ),
        mt5GroupList: flatten(
            JSON.parse(get(swr, 'data')?.[1]?.['value'] || '[]').map((item) => {
                return keys(item).map((key) => ({
                    currency: key,
                    ...item[key],
                }));
            }),
        ),
    };
};

export const useAdminAllFXGroup = (options = {}) => {
    const swr = useSWRTemplate({
        key: ['AdminAllFXGroup'],
        request: async ([_]) => {
            const {result} = await api().get(['fx', 'groups']);
            return result;
        },
        defaultValue: [],
    });

    return {
        ...swr,
    };
};

export const useAdminDefaultGroupSettings = (options = {}) => {
    const {type} = options;
    const swr = useSWRTemplate({
        key: type ? ['forex/defaultGroupSettings', type] : null,
        request: async ([_, _type]) => {
            const {result} = await api().get(['fx', 'default-group-setting'], {
                type: _type,
            });
            const res = flatten(
                JSON.parse(get(result, 'value') || '[]').map((item) => {
                    return keys(item).map((key) => ({
                        currency: key,
                        ...item[key],
                    }));
                }),
            );
            return res;
        },
        defaultValue: [],
    });
    return swr;
};

export const useAdminLeverages = (options = {}) => {
    const {userId} = options;
    const {isAdmin} = useAuth();
    const swr = useSWRTemplate({
        key: userId ? ['AdminLeverages', userId] : null,
        request: async ([_, _userId]) => {
            const {result} = await api().get(['commons', 'leverage-setting'], {
                userId: _userId,
            });
            return (result || []).map((item) => {
                return {
                    label: `1:${item}`,
                    value: item,
                };
            });
        },
        defaultValue: [],
    });

    return {
        ...swr,
    };
};


export const useMtInfo = () => {
    const swr = useSWRTemplate({
        key: ['mt-info'],
        request: async () => {
            const {result} = await api().get(['mt-info'])
            return result
        }
    })
    return swr
}

export const resetSharePassword = async (data) => {
    await api().post(['stock', 'reset-password'], data)
}

export const useUserRefunds = (userId) => {
    const swr = useSWRTemplate({
        key: ['client/refunds', userId],
        request: async () => {
            const {result} = await api().get(['summary', 'worldpay'], cleanObjectNilValue({ userId }))
            return result
        },
        defaultValue: []
    })
    return swr
}