import { Box, Stack } from '@chakra-ui/react';
import CustomTab from 'components/CustomTab';
import Page from 'components/Page';
import { useAdminPermissionSettings } from 'hooks/datahook/adminPermission';
import { useAuth } from 'hooks/useAuth';
import { find, get, last, set, split, trim } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useMatches, useNavigate } from 'react-router-dom';
import { adminPaths, paths } from 'routes/path';
import { StyledButton } from 'components/StyledBtn';

const ClosedPnlLayout = () => {
    const { t } = useTranslation('app');
    const matches = useMatches();
    const matchPath = get(last(matches), 'pathname');
    const mainPath = last(split(trim(matchPath, '/'), '/'));
    const navigate = useNavigate();
    const { state } = useLocation()
    const { roles } = useAuth();
    const { getPermission } = useAdminPermissionSettings({
        roles,
    });
    const [currentTab, setCurrentTab] = useState('bySymbol');
    const [livePositionPath, setLivePositionPath] = useState(adminPaths.riskMtTradeMonitor);
    const tabs = [
        {
            label: t('bySymbol'),
            value: 'bySymbol',
            path: adminPaths.managementReport.bySymbol,
            // hasPermission: getPermission(
            //     'riskManagement.approvals.approvalsKyc',
            // ),
        },
        {
            label: t('byCurrencies'),
            value: 'byCurrencies',
            path: adminPaths.managementReport.byCurrencies,
            // hasPermission: getPermission(
            //     'riskManagement.approvals.approvalsMt45Accounts',
            // ),
        },
        {
            label: t('byGroups'),
            value: 'byGroups',
            path: adminPaths.managementReport.byGroups,
            // hasPermission: getPermission(
            //     'riskManagement.approvals.approvalsIbApplications',
            // ),
        },
        {
            label: t('byBooks'),
            value: 'byBooks',
            path: adminPaths.managementReport.byBooks,
            // hasPermission: getPermission(
            //     'riskManagement.approvals.approvalsFxWithdrawals',
            // ),
        },
        {
            label: t('topGainersOrLosers'),
            value: 'topGainersOrLosers',
            path: adminPaths.managementReport.byTop,
            // hasPermission: getPermission(
            //     'riskManagement.approvals.approvalsWithdrawalAccount',
            // ),
        },
        {
            label: t('byTrades'),
            value: 'byTrades',
            path: adminPaths.managementReport.byTrades,
            // hasPermission: getPermission(
            //     'riskManagement.approvals.approvalsWithdrawalWallet',
            // ),
        },
        {
            label: t('byAccounts'),
            value: 'byAccounts',
            path: adminPaths.managementReport.byAccount,
            // hasPermission: getPermission(
            //     'riskManagement.approvals.approvalsWithdrawalWallet',
            // ),
        },
        {
            label: t('byServers'),
            value: 'byServers',
            path: adminPaths.managementReport.byServer,
            // hasPermission: getPermission(
            //     'riskManagement.approvals.approvalsWithdrawalWallet',
            // ),
        },
    ].filter((item) => item.hasPermission !== false);
    const value =
        find(tabs, (o) => o.path === matchPath)?.value || tabs[0]?.value;

    return (
        <Stack spacing={4}>
            <CustomTab
                tabs={tabs}
                tabOnly
                value={value}
                onChange={(v) => {
                    setCurrentTab(v);
                    switch (v) {
                        case 'bySymbol':
                        case 'byCurrencies':
                            setLivePositionPath(adminPaths.riskMtTradeMonitor);
                            break;
                        case 'topGainersOrLosers':
                            setLivePositionPath(adminPaths.highGainAnalysis);
                            break;
                        case 'byTrades':
                            setLivePositionPath(adminPaths.closedInSencond);
                            break;
                        case 'byAccounts':
                            setLivePositionPath(adminPaths.dailyProfitOver500USD);
                            break;
                    }
                    navigate(find(tabs, (o) => o.value === v)?.path, { state });
                }}
            />
            {!(currentTab === 'byGroups' || currentTab === 'byBooks' || currentTab === 'byServers') &&
                < StyledButton position="absolute" top={'160px'} right={'30px'} onClick={() => navigate(livePositionPath)}>
                    {t('livePositions')}
                </StyledButton>
            }
            <Box width={'100%'}>
                <Outlet />
            </Box>
        </Stack >
    );
};

export default ClosedPnlLayout;
