import {
    useSWRPaginationTemplate,
    useSWRTemplate,
} from 'hooks/dataHooksTemplate';
import { api } from './common';
import { cleanObjectNilValue } from '../../utils/clean';
import { get } from 'lodash';
import { processPlatformAccount } from '../../data/platformAccount';
import { useAuth } from 'hooks/useAuth';
import { addDays, addMonths, format, parseISO } from 'date-fns';
import {
    currencyFormatorWithDecimal,
    formatMonthDataAndYear,
    formatDateTimes
} from 'utils/formator';
import Excel from 'exceljs';
import { processTotalTrades, keepTwoDecimalPlaces, topGainOrLoss, byCurrencies, byCurrenciesDetails, processAccountByYear } from 'data/managementReport'
import { convertSource } from 'page/app/ConstantGroup';

export const useVolumeAnalysis = ({ startDate, endDate, source }) => {
    const swr = useSWRTemplate({
        key: ['volume-analysis', startDate, endDate, source],
        request: async ([_, _startDate, _endDate, _source]) => {
            const { result } = await api().get(
                ['mtreport', 'lot', 'close'],
                cleanObjectNilValue({
                    startDate: _startDate,
                    endDate: _endDate,
                    source: _source === 'MT4' ? 'MT4s1' : _source,
                }),
            );
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useVolumeAnalysisByYear = ({ year, source }) => {
    const swr = useSWRTemplate({
        key: ['volume-analysis-by-year', year, source],
        request: async ([_, _year, _source]) => {
            const { result } = await api().get(
                ['mtreport', 'lot', 'year-vol'],
                cleanObjectNilValue({
                    year: _year,
                    source: _source === 'MT4' ? 'MT4s1' : _source,
                }),
            );
            return result.map((item) => {
                console.log(new Date(get(item, 'mtDate')));
                return {
                    ...item,
                    date: format(
                        parseISO(new Date(get(item, 'mtDate')).toISOString()),
                        'PP',
                    ).split(' '),
                };
            });
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useVolumeAnalysisBySymbol = ({ startDate, endDate, source }) => {
    const swr = useSWRTemplate({
        key: ['volume-analysis-by-symbol', startDate, endDate, source],
        request: async ([_, _startDate, _endDate, _source]) => {
            const { result } = await api().get(
                ['mtreport', 'lot', 'symbols'],
                cleanObjectNilValue({
                    startDate: _startDate,
                    endDate: _endDate,
                    source: _source === 'MT4' ? 'MT4s1' : _source,
                }),
            );
            return result.sort((a, b) => {
                return b.Total - a.Total;
            });
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useVolumeAnalysisByAllSymbol = ({ startDate, endDate, source }) => {
    const swr = useSWRPaginationTemplate({
        key: ['volume-analysis-by-all-symbol', startDate, endDate, source],
        request: async ([_, _startDate, _endDate, _source], _pageIndex, _pageSize) => {
            const { result } = await api().get(
                ['mtreport', 'lot', 'all-symbols'],
                cleanObjectNilValue({
                    startDate: _startDate,
                    endDate: _endDate,
                    source: _source === 'MT4' ? 'MT4s1' : _source,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize
                }),
            );
            return {
                result: {
                    items: get(result, 'items').map(item => {
                        return {
                            ...item,
                            short: 0 - get(item, 'short'),
                            net: Number(get(item, 'long')) + (0 - get(item, 'short'))
                        }
                    }),
                    totalItems: get(result, 'totalItems')
                }
            }
        },
        defaultValue: { items: [], totalItems: 0 },
    });
    return {
        ...swr,
    };
};

export const useAccountAnalysis = (options = {}) => {
    const { startDate, endDate, source } = options;
    const swr = useSWRTemplate({
        key: ['account-analysis', startDate, endDate, source],
        request: async ([_, _startDate, _endDate, _source]) => {
            const query = {
                startDate: _startDate,
                endDate: _endDate,
                source: _source === 'MT4' ? 'MT4s1' : _source,
            };
            const { result } = await api().get(
                ['mtreport', 'account', 'by-date'],
                cleanObjectNilValue(query),
            );
            return result.map((item) => {
                return {
                    mt4s1: get(item, 'MT4s1'),
                    mt4s2: get(item, 'MT4s2'),
                    mt4s3: get(item, 'MT4s3'),
                    mt5: get(item, 'MT5'),
                    total: get(item, 'Total'),
                    date: get(item, 'mtDate'),
                };
            });
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useAccountAnalysisByYear = (options = {}) => {
    const { year, source } = options;
    const swr = useSWRTemplate({
        key: ['volume/analysis-by-year', year, source],
        request: async ([_, _year, _source]) => {
            const query = {
                year: _year,
                source: _source === 'MT4' ? 'MT4s1' : _source,
            };
            const { result } = await api().get(
                ['mtreport', 'account', 'by-year-new'],
                cleanObjectNilValue(query),
            );
            return processAccountByYear(result, _year);

            return result.map((item) => {
                return {
                    mt4s1: get(item, 'MT4s1'),
                    mt4s2: get(item, 'MT4s2'),
                    mt4s3: get(item, 'MT4s3'),
                    mt5: get(item, 'MT5'),
                    total: get(item, 'Total'),
                    date: format(new Date(get(item, 'mtDate')), 'PP').split(
                        ' ',
                    ),
                };
            });
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useAccountAnalysisByCountry = (options = {}) => {
    const { startDate, endDate, source } = options;
    const swr = useSWRTemplate({
        key: ['account-analysis-by-country', startDate, endDate, source],
        request: async ([_, _startDate, _endDate, _source]) => {
            const query = {
                startDate: _startDate,
                endDate: _endDate,
                source: _source === 'MT4' ? 'MT4s1' : _source,
            };
            const { result } = await api().get(
                ['mtreport', 'account', 'by-country'],
                cleanObjectNilValue(query),
            );
            return result
                .map((item) => {
                    return {
                        name: get(item, 'region'),
                        value: get(item, 'value'),
                    };
                })
                .sort((a, b) => {
                    return b.value - a.value;
                });
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useAccountAnalysisByAllCountry = (options = {}) => {
    const { startDate, endDate, source } = options;
    const swr = useSWRPaginationTemplate({
        key: ['account-analysis-by-all-country', startDate, endDate, source],
        request: async ([_, _startDate, _endDate, _source], _pageIndex, _pageSize) => {
            const query = {
                startDate: _startDate,
                endDate: _endDate,
                source: _source === 'MT4' ? 'MT4s1' : _source,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize
            };
            const { result } = await api().get(
                ['mtreport', 'account', 'by-all-country'],
                cleanObjectNilValue(query),
            );
            return {
                result: {
                    items: get(result, 'items'),
                    totalItems: get(result, 'totalItems')
                }
            }
        },
        defaultValue: { items: [], totalItems: [] },
    });
    return {
        ...swr,
    };
};

export const useAccountAnalysisByTop = (options = {}) => {
    const { startDate, endDate, source } = options;
    const swr = useSWRPaginationTemplate({
        key: ['volume-analysis-by-top', startDate, endDate, source],
        request: async ([_, _startDate, _endDate, _source], _pageIndex, _pageSize) => {
            const query = {
                startDate: _startDate,
                endDate: _endDate,
                source: _source === 'MT4' ? 'MT4s1' : _source,
                pageIndex: _pageIndex - 1,
                pageSize: 25
            };
            const { result } = await api().get(
                ['mtreport', 'account', 'top-account'],
                cleanObjectNilValue(query),
            );
            return {
                result: {
                    items: get(result, 'items').map((item) => {
                        return {
                            source: get(item, 'source'),
                            login: get(item, 'login'),
                            leverage: get(item, 'leverage'),
                            group: get(item, 'group'),
                            lots: Number(get(item, 'lots')) || 0,
                            avgLots: get(item, 'lots') / get(item, 'count'),
                            totalTrades: get(item, 'count'),
                        }
                    }).sort((a, b) => {
                        return b.lots - a.lots;
                    }),
                    totalItems: get(result, 'totalItems')
                }
            }
        },
        defaultValue: { items: [], totalItems: 0 },
    });
    return {
        ...swr,
    };
};

export const useAccountAnalysisByAllTop = (options = {}) => {
    const { startDate, endDate, source } = options;
    const swr = useSWRPaginationTemplate({
        key: ['volume-analysis-by-all-top', startDate, endDate, source],
        request: async (
            [_, _startDate, _endDate, _source],
            _pageIndex,
            _pageSize,
        ) => {
            const query = {
                startDate: _startDate,
                endDate: _endDate,
                source: _source === 'MT4' ? 'MT4s1' : _source,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            };
            const { result } = await api().get(
                ['mtreport', 'account', 'top-account'],
                cleanObjectNilValue(query),
            );
            return {
                result: {
                    items: get(result, 'items').map((item) => {
                        return {
                            source: get(item, 'source'),
                            login: get(item, 'login'),
                            leverage: get(item, 'leverage'),
                            group: get(item, 'group'),
                            lots: get(item, 'lots'),
                            avgLots: get(item, 'lots') / get(item, 'count'),
                            totalTrades: get(item, 'count'),
                        };
                    }),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: { items: [], totalItems: 0 },
    });
    return {
        ...swr,
    };
};

export const useClosedAnalysisByDates = (options = {}) => {
    let { startDate, endDate, source, bookType } = options;
    source === 'MT4' && (source = 'MT4s1');
    const swr = useSWRTemplate({
        key: ['closed-analysis-by-dates', startDate, endDate, source, bookType],
        request: async ([_, _startDate, _endDate, _source, _bookType]) => {
            const { result } = await api().get(
                ['mtreport', 'pnl', 'data'],
                cleanObjectNilValue({
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    source: _source,
                    bookType: _bookType,
                }),
            );
            return result.map(keepTwoDecimalPlaces);
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useClosedAnalysisByYear = (options = {}) => {
    let { year, source, bookType } = options;
    source === 'MT4' && (source = 'MT4s1');
    const swr = useSWRTemplate({
        key: year ? ['closed-analysis-by-year', year, source, bookType] : null,
        request: async ([_, _year, _source, _bookType]) => {
            const { result } = await api().get(
                ['mtreport', 'pnl', 'year-close'],
                cleanObjectNilValue({
                    year: _year,
                    source: _source,
                    bookType: _bookType,
                }),
            );
            return result.map(processTotalTrades);
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useClosedAnalysisByServers = (options = {}) => {
    let { startDate, endDate, source, bookType } = options;
    source === 'MT4' && (source = 'MT4s1');
    const swr = useSWRTemplate({
        key: ['closed-analysis-by-servers', startDate, endDate, source, bookType],
        request: async ([_, _startDate, _endDate, _source, _bookType]) => {
            const { result } = await api().get(
                ['mtreport', 'pnl', 'close'],
                cleanObjectNilValue({
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    source: _source,
                    bookType: _bookType,
                }),
            );
            return result.map(keepTwoDecimalPlaces);
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useClosedAnalysisByEquity = (options = {}) => {
    let { year, source, bookType } = options;
    source === 'MT4' && (source = 'MT4s1');
    const swr = useSWRTemplate({
        key: ['closed-analysis-by-equity', year, source, bookType],
        request: async ([_, _year, _source, _bookType]) => {
            const { result } = await api().get(
                ['mtreport', 'pnl', 'year-equity'],
                cleanObjectNilValue({
                    year: _year,
                    source: _source,
                    bookType: _bookType,
                }),
            );
            return result.map(processTotalTrades);
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useClosedAnalysisAccountRange = (options = {}) => {
    let { startDate, endDate, source, bookType } = options;
    source === 'MT4' && (source = 'MT4s1');
    const swr = useSWRTemplate({
        key: ['closed-analysis-account-range', startDate, endDate, source, bookType],
        request: async ([_, _startDate, _endDate, _source, _bookType]) => {
            const { result } = await api().get(
                ['mtreport', 'pnl', 'account-range'],
                cleanObjectNilValue({
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    source: _source,
                    bookType: _bookType,
                }),
            );
            const order = [
                '-500k',
                '-100k',
                '-20k',
                '-5k',
                '0',
                '5k',
                '20k',
                '100k',
                '500k',
            ];
            const data = order
                .map((key) => result[0][key])
                .filter((value) => value !== undefined);
            return data;
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useTotalUnrealisedPnl = (options = {}) => {
    const { startDate, endDate, source, year } = options;
    const swr = useSWRTemplate({
        key: ['total-unrealised-pnl', startDate, endDate, source, year],
        request: async ([_, _startDate, _endDate, _source, _year]) => {
            const { result } = await api().get(
                ['mtreport', 'total'],
                cleanObjectNilValue({
                    source: convertSource(_source),
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    year: _year,
                }),
            );
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useHoldingPositionProfit = (options = {}) => {
    const { startDate, endDate } = options;
    const swr = useSWRTemplate({
        key: ['holding-positions-profit', startDate, endDate],
        request: async ([_, _startDate, _endDater]) => {
            // const {result} = await api().get([''])
            // return result
            return new Array(30).fill(0).map((item, index) => {
                const opening = Math.random() * 1000;
                const closed = Math.random() * 1000;
                return {
                    opening: opening,
                    closed: closed,
                    total: opening + closed,
                    date: addDays(new Date(), 0 - index).toISOString(),
                };
            });
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useHoldingPositionPie = (options = {}) => {
    const { startDate, endDate } = options;
    const swr = useSWRTemplate({
        key: ['holding-positions-pie', startDate, endDate],
        request: async ([_, _startDate, _endDater]) => {
            // const {result} = await api().get([''])
            // return result
            const mt4s1 = Math.random() * 1000;
            const mt4s2 = Math.random() * 1000;
            const mt4s3 = Math.random() * 1000;
            const mt5 = Math.random() * 1000;
            const total = mt4s1 + mt4s2 + mt4s3 + mt5;
            return {
                mt4s1: mt4s1,
                mt4s2: mt4s2,
                mt4s3: mt4s3,
                mt5: mt5,
                total: total,
            };
        },
        defaultValue: {},
    });
    return {
        ...swr,
    };
};

export const useHoldingPositionPieVolume = (options = {}) => {
    const { startDate, endDate } = options;
    const swr = useSWRTemplate({
        key: ['holding-positions-pie-volume', startDate, endDate],
        request: async ([_, _startDate, _endDater]) => {
            // const {result} = await api().get([''])
            // return result
            const mt4s1 = Math.random() * 1000;
            const mt4s2 = Math.random() * 1000;
            const mt4s3 = Math.random() * 1000;
            const mt5 = Math.random() * 1000;
            const total = mt4s1 + mt4s2 + mt4s3 + mt5;
            return {
                mt4s1: mt4s1,
                mt4s2: mt4s2,
                mt4s3: mt4s3,
                mt5: mt5,
                total: total,
            };
        },
        defaultValue: {},
    });
    return {
        ...swr,
    };
};

export const useHoldingPositionsOpeningBySymbol = ({
    startDate,
    endDate,
    source,
}) => {
    const swr = useSWRTemplate({
        key: [
            'holding-positions-opening-by-symbol',
            startDate,
            endDate,
            source,
        ],
        request: async ([_, _startDate, _endDate, _source]) => {
            const { result } = await api().get(
                ['mtreport', 'lot', 'symbols'],
                cleanObjectNilValue({
                    startDate: _startDate,
                    endDate: _endDate,
                    source: 'MT4s1',
                }),
            );
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useHoldingPositionsVolumeBySymbol = ({
    startDate,
    endDate,
    source,
}) => {
    const swr = useSWRTemplate({
        key: ['holding-positions-volume-by-symbol', startDate, endDate, source],
        request: async ([_, _startDate, _endDate, _source]) => {
            const { result } = await api().get(
                ['mtreport', 'lot', 'symbols'],
                cleanObjectNilValue({
                    startDate: _startDate,
                    endDate: _endDate,
                    source: 'MT4s1',
                }),
            );
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useClosedAnalysisPnlBySymbols = (options = {}) => {
    let { startDate, endDate, source, sort, bookType } = options
    source === "MT4" && (source = "MT4s1")
    const swr = useSWRPaginationTemplate({
        key: ['closed-analysis-by-symbols', startDate, endDate, source, sort, bookType],
        request: async ([_, _startDate, _endDate, _source, _sort, _bookType], _pageIndex, _pageSize) => {
            const { result } = await api().get(['mtreport', 'pnl', 'symbols'], cleanObjectNilValue({
                startDate: _startDate ? formatMonthDataAndYear(_startDate) : null,
                endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                source: _source,
                sort: _sort,
                bookType: _bookType,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize
            }))
            return {
                result: {
                    items: get(result, 'items').map(processTotalTrades),
                    totalItems: get(result, 'totalItems')
                }
            }
        },
        defaultValue: { items: [], totalItems: 0 },
    });
    return {
        ...swr,
    };
};

export const useClosedAnalysisPnlByCurrencies = (options = {}) => {
    let { startDate, endDate, source, bookType } = options;
    source === 'MT4' && (source = 'MT4s1');
    const swr = useSWRPaginationTemplate({
        key: ['closed-analysis-by-currencies', startDate, endDate, source, bookType],
        request: async ([_, _startDate, _endDate, _source, _bookType], _pageIndex, _pageSize) => {
            const { result } = await api().get(
                ['mtreport', 'pnl', 'currencies'],
                cleanObjectNilValue({
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    source: _source,
                    bookType: _bookType,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize
                }),
            );
            return {
                result: {
                    items: get(result, 'items').map(byCurrencies),
                    totalItems: get(result, 'totalItems')
                }
            }
        },
        defaultValue: { items: [], totalItems: 0 },
    });
    return {
        ...swr,
    };
};

export const useClosedAnalysisPnlByCurrenciesDetails = (options = {}) => {
    let { startDate, endDate, source, bookType } = options;
    source === 'MT4' && (source = 'MT4s1');
    const swr = useSWRPaginationTemplate({
        key: ['closed-analysis-pnl-by-currencies-details', startDate, endDate, source, bookType],
        request: async ([_, _startDate, _endDate, _source, _bookType], _pageIndex, _pageSize) => {
            const { result } = await api().get(
                ['mtreport', 'pnl', 'currencies-details'],
                cleanObjectNilValue({
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    source: _source,
                    bookType: _bookType,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize
                }),
            );
            return {
                result: {
                    items: get(result, 'items').map(byCurrenciesDetails),
                    totalItems: get(result, 'totalItems')
                }
            }
        },
        defaultValue: { items: [], totalItems: 0 },
    });
    return {
        ...swr,
    };
};

export const useClosedAnalysisPnlByGroups = (options = {}) => {
    let { startDate, endDate, source, bookType } = options;
    source === 'MT4' && (source = 'MT4s1');
    const swr = useSWRPaginationTemplate({
        key: ['closed-analysis-by-groups', startDate, endDate, source, bookType],
        request: async ([_, _startDate, _endDate, _source, _bookType], _pageIndex, _pageSize) => {
            const { result } = await api().get(
                ['mtreport', 'pnl', 'groups'],
                cleanObjectNilValue({
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    source: _source,
                    bookType: _bookType,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize
                }),
            );
            return {
                result: {
                    items: get(result, 'items').map(processTotalTrades),
                    totalItems: get(result, 'totalItems')
                }
            }
        },
        defaultValue: { items: [], totalItems: 0 },
    });
    return {
        ...swr,
    };
};

export const useClosedAnalysisPnlByBooks = (options = {}) => {
    let { startDate, endDate, source, bookType } = options;
    source === 'MT4' && (source = 'MT4s1');
    const swr = useSWRPaginationTemplate({
        key: ['closed-analysis-by-books', startDate, endDate, source, bookType],
        request: async ([_, _startDate, _endDate, _source, _bookType], _pageIndex, _pageSize) => {
            const { result } = await api().get(
                ['mtreport', 'pnl', 'books'],
                cleanObjectNilValue({
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    source: _source,
                    bookType: _bookType,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize
                }),
            );
            return {
                result: {
                    items: get(result, 'items').map(processTotalTrades),
                    totalItems: get(result, 'totalItems')
                }
            };
        },
        defaultValue: { items: [], totalItems: 0 },
    });
    return {
        ...swr,
    };
};

export const useClosedAnalysisTopGainOrLoss = (options = {}) => {
    let { startDate, endDate, source, bookType } = options;
    source === 'MT4' && (source = 'MT4s1');
    const swr = useSWRPaginationTemplate({
        key: ['closed-analysis-top-gain-or-loss', startDate, endDate, source, bookType],
        request: async ([_, _startDate, _endDate, _source, _bookType], _pageIndex, _pageSize) => {
            const { result } = await api().get(['mtreport', 'pnl', 'top-gain-or-loss'], cleanObjectNilValue({
                startDate: _startDate ? formatMonthDataAndYear(_startDate) : null,
                endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                source: _source,
                bookType: _bookType,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize
            }),
            );
            return {
                result: {
                    items: get(result, 'items').map(topGainOrLoss),
                    totalItems: get(result, 'totalItems')
                }
            }
        },
        defaultValue: { tems: [], totalItems: 0 },
    });
    return {
        ...swr,
    };
};

export const useClosedAnalysisAccountTrades = (options = {}) => {
    let { startDate, endDate, source, login, bookType } = options;
    source === 'MT4' && (source = 'MT4s1');
    const swr = useSWRPaginationTemplate({
        key: ['closed-analysis-account-trades', startDate, endDate, source, login, bookType],
        request: async ([_, _startDate, _endDate, _source, _login, _bookType], _pageIndex, _pageSize) => {
            const { result } = await api().get(
                ['mtreport', 'pnl', 'account-trades'],
                cleanObjectNilValue({
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    source: _source,
                    login: _login,
                    bookType: _bookType,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize
                }),
            );
            return {
                result: {
                    items: get(result, 'items').map(processTotalTrades),
                    totalItems: get(result, 'totalItems')
                }
            }

        },
        defaultValue: { tems: [], totalItems: 0 },
    });
    return {
        ...swr,
    };
};

export const useClosedAnalysisGainTrades = (options = {}) => {
    let { year, startDate, endDate, source, bookType, holding, profits, sort } = options;
    source === 'MT4' && (source = 'MT4s1');
    const swr = useSWRPaginationTemplate({
        key: ['closed-analysis-gain-trades', year, startDate, endDate, source, bookType, holding, profits, sort],
        request: async ([_, _year, _startDate, _endDate, _source, _bookType, _holding, _profits, _sort], _pageIndex, _pageSize) => {
            const { result } = await api().get(
                ['mtreport', 'pnl', 'pnl-analysis'],
                cleanObjectNilValue({
                    year: _year,
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    source: _source,
                    bookType: _bookType,
                    holding: _holding ? _holding : 20,
                    profits: _profits ? _profits : 500,
                    sort: _sort,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize
                }),
            );
            return {
                result: {
                    items: get(result, 'items').map(processTotalTrades),
                    totalItems: get(result, 'totalItems')
                }
            };
        },
        defaultValue: { items: [], totalItems: 0 },
    });
    return {
        ...swr,
    };
};

export const downloadClosedAnalysisGainTrades = async (params = {}) => {
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet(
        'Risky trades of closed PnL By Trades',
    );
    sheet.columns = [
        { header: 'Source', key: 'source' },
        { header: 'Ticket', key: 'ticket' },
        { header: 'Account', key: 'account' },
        { header: 'Name', key: 'name' },
        { header: 'Group', key: 'group' },
        { header: 'Leverage', key: 'leverage' },
        { header: 'Currency', key: 'currency' },
        { header: 'Symbol', key: 'symbol' },
        { header: 'Lots', key: 'lots' },
        { header: 'Open time', key: 'open_time' },
        { header: 'Closed time', key: 'close_time' },
        { header: 'Holding seconds', key: 'holding' },
        { header: 'Net Profits', key: 'profits' },
        { header: 'Net profits(USD)', key: 'profits_usd' },
        { header: 'Commission', key: 'commission' },
        { header: 'Comment', key: 'comment' },
    ];
    const { year, startDate, endDate, source, bookType, holding, profits, sort } = params;
    const { result } = await api().get(
        ['mtreport', 'pnl', 'pnl-analysis'],
        cleanObjectNilValue({
            year: year,
            startDate: startDate ? formatMonthDataAndYear(startDate) : null,
            endDate: endDate ? formatMonthDataAndYear(endDate) : null,
            source: source,
            bookType: bookType,
            holding: holding ? holding : 20,
            profits: profits ? profits : 500,
            sort: sort
        }),
    );
    for (const grant of result) {
        const row = [];
        for (const col of sheet.columns) {
            switch (col.key) {
                case 'source':
                    row.push(grant.source);
                    break;
                case 'ticket':
                    row.push(grant.ticket);
                    break;
                case 'account':
                    row.push(grant.account);
                    break;
                case 'name':
                    row.push(grant.name);
                    break;
                case 'group':
                    row.push(grant.group);
                    break;
                case 'leverage':
                    row.push(grant.leverage);
                    break;
                case 'currency':
                    row.push(grant.currency);
                    break;
                case 'symbol':
                    row.push(grant.symbol);
                    break;
                case 'lots':
                    row.push(
                        currencyFormatorWithDecimal(
                            grant.lots,
                            '',
                            2,
                            true,
                        ),
                    );
                    break;
                case 'open_time':
                    row.push(formatDateTimes(grant.open_time));
                    break;
                case 'close_time':
                    row.push(formatDateTimes(grant.close_time));
                    break;
                case 'holding':
                    row.push(grant.holding);
                    break;
                case 'profits':
                    row.push(
                        currencyFormatorWithDecimal(
                            grant.profits,
                            '',
                            2,
                            true,
                        ),
                    );
                    break;
                case 'profits_usd':
                    row.push(
                        currencyFormatorWithDecimal(
                            grant.profits_usd,
                            '',
                            2,
                            true,
                        ),
                    );
                    break;
                case 'commission':
                    row.push(grant.commission);
                    break;
                case 'comment':
                    row.push(grant.comment);
                    break;
            }
        }
        sheet.addRow(row);
    }
    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], { type: 'application/vnd.openxmlformats' }),
    );
    a.download = 'Risky trades of closed PnL By Trades.xlsx';
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
};

export const useClosedAnalysisGainAccounts = (options = {}) => {
    let { year, startDate, endDate, source, bookType, account, group, profit } = options;
    source === 'MT4' && (source = 'MT4s1');
    const swr = useSWRPaginationTemplate({
        key: [
            'closed-analysis-gain-accounts',
            year,
            startDate,
            endDate,
            source,
            bookType,
            account,
            group,
            profit,
        ],
        request: async ([_, _year, _startDate, _endDate, _source, _bookType, _account, _group, _profit], _pageIndex, _pageSize) => {
            const { result } = await api().get(
                ['mtreport', 'pnl', 'profits-ranking'],
                cleanObjectNilValue({
                    year: _year,
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    source: _source,
                    bookType: _bookType,
                    account: _account,
                    group: _group,
                    profit: _profit,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize
                }),
            );
            return {
                result: {
                    items: get(result, 'items').map(processTotalTrades),
                    totalItems: get(result, 'totalItems')
                }
            };
        },
        defaultValue: { items: [], totalItems: 0 },
    });
    return {
        ...swr,
    };
};

export const downloadClosedAnalysisGainAccounts = async (params = {}) => {
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet(
        'Risky trades of closed PnL By Accounts',
    );
    sheet.columns = [
        { header: 'Date', key: 'date' },
        { header: 'Account', key: 'login' },
        { header: 'Client', key: 'name' },
        { header: 'Group', key: 'group' },
        { header: 'Leverage', key: 'leverage' },
        { header: 'Currency', key: 'currency' },
        { header: 'Last Day Balance', key: 'balance_prev' },
        { header: 'Day In', key: 'deposit' },
        { header: 'Day Out', key: 'withdraw' },
        { header: 'Net Profit', key: 'profit' },
        { header: 'Net Profit(USD)', key: 'profits_usd' },
    ];
    const { year, startDate, endDate, source, bookType, account, group, profit } = params;
    const { result } = await api().get(
        ['mtreport', 'pnl', 'profits-ranking'],
        cleanObjectNilValue({
            year: year,
            startDate: startDate ? formatMonthDataAndYear(startDate) : null,
            endDate: endDate ? formatMonthDataAndYear(endDate) : null,
            source: source,
            bookType: bookType,
            account: account,
            group: group,
            profit: profit
        }),
    );
    for (const grant of result) {
        const row = [];
        for (const col of sheet.columns) {
            switch (col.key) {
                case 'date':
                    row.push(grant.date);
                    break;
                case 'login':
                    row.push(grant.login);
                    break;
                case 'name':
                    row.push(grant.name);
                    break;
                case 'group':
                    row.push(grant.group);
                    break;
                case 'leverage':
                    row.push(grant.leverage);
                    break;
                case 'currency':
                    row.push(grant.currency);
                    break;
                case 'balance_prev':
                    row.push(
                        currencyFormatorWithDecimal(
                            grant.balance_prev,
                            '',
                            2,
                            true,
                        ),
                    );
                    break;
                case 'deposit':
                    row.push(
                        currencyFormatorWithDecimal(
                            grant.deposit,
                            '',
                            2,
                            true,
                        ),
                    );
                    break;
                case 'withdraw':
                    row.push(
                        currencyFormatorWithDecimal(
                            grant.withdraw,
                            '',
                            2,
                            true,
                        ),
                    );
                    break;
                case 'profit':
                    row.push(
                        currencyFormatorWithDecimal(
                            grant.profit,
                            '',
                            2,
                            true,
                        ),
                    );
                    break;
                case 'profits_usd':
                    row.push(
                        currencyFormatorWithDecimal(
                            grant.profits_usd,
                            '',
                            2,
                            true,
                        ),
                    );
                    break;
            }
        }
        sheet.addRow(row);
    }
    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], { type: 'application/vnd.openxmlformats' }),
    );
    a.download = 'Risky trades of closed PnL By Accounts.xlsx';
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
};

export const useClosedAnalysisByServersDetails = (options = {}) => {
    let { startDate, endDate, source, bookType } = options;
    source === 'MT4' && (source = 'MT4s1');
    const swr = useSWRPaginationTemplate({
        key: ['closed-analysis-by-servers-details', startDate, endDate, source, bookType],
        request: async ([_, _startDate, _endDate, _source, _bookType], _pageIndex, _pageSize) => {
            const { result } = await api().get(
                ['mtreport', 'pnl', 'servers-details'],
                cleanObjectNilValue({
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    source: _source,
                    bookType: _bookType,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize
                }),
            );
            return {
                result: {
                    items: get(result, 'items').map(processTotalTrades),
                    totalItems: get(result, 'totalItems')
                }
            };
        },
        defaultValue: { items: [], totalItems: 0 },
    });
    return {
        ...swr,
    };
};
